import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

const OrderList = () => {
  const [filterModal, setFilterModal] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [lastOrderData, setLastOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [canIsend, setCanISend] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");
        const response = await fetch(`${apiKey}/nam/shops/myLastOrder`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        setCanISend(result.canICreateOrder);
        setOrderData(result.order);
        setLastOrderData(result.order);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content">
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {error && (
        <p className="text-danger">Gözlənilməz xəta baş verdi: {error}</p>
      )}
      {orderData && (
        <Fragment>
          <div className="d-flex flex-wrap align-items-center pb-3">
            <Link
              to="/orders/create"
              className={`btn btn-info ${canIsend ? "" : "disabled"}`}
            >
              Sifariş yarat
            </Link>
            <Button
              onClick={() => setFilterModal(!filterModal)}
              color="warning"
            >
              Filtrlə
            </Button>
            <FilterModal
              modal={filterModal}
              setModal={setFilterModal}
              setIsFiltered={setIsFiltered}
              isFiltered={isFiltered}
              setOrderData={setOrderData}
              lastOrderData={lastOrderData}
            />
          </div>
          {canIsend ? (
            <div class="alert alert-success" role="alert">
              <h4 class="alert-heading">
                {isFiltered
                  ? "Filter Olunmus Sifarisler"
                  : "Son Sifarişleriniz Burdadır!"}
              </h4>
            </div>
          ) : (
            <div class="alert alert-danger" role="alert">
              <h4 class="alert-heading">Sifariş qebul muddeti bitib!</h4>
              <p>Zehmet olmasa sifarislerinizi 12:00-a dek gonderin.</p>
              <p class="mb-0">Açılması üçün ya müraciət edin ya da Gözləyin</p>
            </div>
          )}
          <Accordion data-bs-theme="dark" defaultActiveKey="0" flush>
            {orderData.map((order, key) => {
              return (
                <Accordion.Item key={key} eventKey={key}>
                  <Accordion.Header className="">
                    Tarix: {order.date.split("T")[0]} & {}
                    {order.date.split("T")[1].substring(0, 5)} (Cəmi:{" "}
                    {order.total.toFixed(2)}
                    ₼)
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table className="tablesorter" responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>Məhsulun adı</th>
                          <th>Barkod</th>
                          <th>Miqdarı</th>
                          <th>Qiyməti (kq,ədəd)</th>
                          <th>Cəmi</th>
                          <th>Qeyd</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.orderItems &&
                          order.orderItems.map((item, itemKey) => {
                            return (
                              <tr key={itemKey}>
                                <td>{item.productStockName}</td>
                                <td>{item.productBarcode}</td>
                                <td>{item.value}</td>
                                <td>{item.productPrice.toFixed(2)}</td>
                                <td>
                                  {(item.productPrice * item.value).toFixed(2)}
                                </td>
                                <td>
                                  {item.note || item.note.length > 0
                                    ? item.note
                                    : "-"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Fragment>
      )}
    </div>
  );
};

const FilterModal = ({
  modal,
  setModal,
  setIsFiltered,
  isFiltered,
  setOrderData,
  lastOrderData,
}) => {
  const [error, setError] = useState(null);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const [loading, setLoading] = useState(true);
  const [canIsend, setCanISend] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");
        const response = await fetch(
          `${apiKey}/nam/shops/myOrders?start=${dateTime.min.toLocaleDateString(
            "en-En",
            options
          )}&end=${dateTime.max.toLocaleDateString("en-En", options)}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();

        setCanISend(result.canICreateOrder);
        setOrderData(result.shop);
        setIsChange(false);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isChange]);

  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };

  const Filter = () => {
    setIsFiltered(true);
    setIsChange(true);
    setModal(!modal);
  };

  const Clear = () => {
    if (isFiltered) {
      setOrderData(lastOrderData);
      setIsFiltered(false);
    }
    setModal(!modal);
  };
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(!modal)}>
        Sifarişləri Filtrlə
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="min">Tarixdən</Label>
          <Input
            className="text-dark"
            value={dateTime.min.toISOString().split("T")[0]}
            id="min"
            name="min"
            placeholder="Tarixdən"
            type="date"
            onChange={InputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="max">Tarixə</Label>
          <Input
            onChange={InputChange}
            className="text-dark"
            value={dateTime.max.toISOString().split("T")[0]}
            id="max"
            name="max"
            placeholder="Tarixdən"
            type="date"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-info" onClick={Filter}>
          Filtrlə
        </button>{" "}
        <Button color="secondary" onClick={Clear}>
          {isFiltered ? "Təmizlə" : "İmtina Et"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default OrderList;
