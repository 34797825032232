import Categories from "views/Categories/CategoryList";
import Dashboard from "views/Dashboard";
import Products from "views/Products/ProductList";
import ShopList from "views/Shops/ShopList";
import Downloads from "views/Downloads";
import DiscountedPrice from "views/Orders/DiscountedPrice";
import DisplayProductPrice from "views/Orders/DisplayProductPrice";
import OrderList from "views/Orders/OrderList";
import ProductChangeVisibility from "views/ProductChangeVisibility";
import Reports from "views/Reports";
import AreaManagerList from "views/AreaManagers/AreaManagerList";
import AreaManagerOrders from "views/Manager/AreaManagerOrders";
import CheckList from "views/Manager/CheckList";
import AreaManagerReports from "views/Manager/AreaManagerReport";
import ShopAreaReports from "views/Orders/ShopAreaReports";
import AreaManagerShops from "views/Manager/AreaManagerShops";
import Checklists from "views/CheckLists";

var routes = [
  {
    path: "/dashboard",
    name: "Ana Səhifə",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/areamanagers",
    name: "Şöbə Müdiri",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <AreaManagerList />,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Kateqoriyalar",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Categories />,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Məhsullar",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Products />,
    layout: "/admin",
  },
  {
    path: "/shops",
    name: "Mağazalar",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <ShopList />,
    layout: "/admin",
  },
  {
    path: "/downloads",
    name: "Yükləmələr & Silmeler",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <Downloads />,
    layout: "/admin",
  },
  {
    path: "/productChangeVisibility",
    name: "Active / Passive",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <ProductChangeVisibility />,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Raporlar",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <Reports />,
    layout: "/admin",
  },
  {
    path: "/checklists",
    name: "Checklist",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <Checklists />,
    layout: "/admin",
  },
  {
    path: "/orderlist",
    name: "Ana Sehifə",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <OrderList />,
    layout: "/orders",
  },
  {
    path: "/discountedprice",
    name: "Endirimli Qiymətlər",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <DiscountedPrice />,
    layout: "/orders",
  },
  {
    path: "/displayproductprice",
    name: "Satiş Qiymətləri",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: <DisplayProductPrice />,
    layout: "/orders",
  },
  {
    path: "/order",
    name: "Sifarisler",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <AreaManagerOrders />,
    layout: "/manager",
  },
  {
    path: "/checklist",
    name: "Check List",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <CheckList />,
    layout: "/manager",
  },
  {
    path: "/areaReport",
    name: "Raporlar",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <AreaManagerReports />,
    layout: "/manager",
  },
  {
    path: "/areaShop",
    name: "Magazalar",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <AreaManagerShops />,
    layout: "/manager",
  },
  {
    path: "/shopareaReport",
    name: "Raporlar",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <ShopAreaReports />,
    layout: "/orders",
  },
];
export default routes;
