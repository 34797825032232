import { apiKey } from "BaseApi/ApiKey";
import Delete from "components/Actions/Delete";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {useLocation, useParams } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

const ShopOrders = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const dateMin = query.get("dateMin") ? query.get("dateMin") : new Date();
  const dateMax = query.get("dateMax") ? query.get("dateMax") : new Date();

  const { id } = useParams();
  const [orderModal, setOrderModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [data, setData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const [dateTime, setDateTime] = useState({
    min: new Date(dateMin),
    max: new Date(dateMax),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");
        const response = await fetch(
          `${apiKey}/nam/shops/getShopOrders${id}?start=${dateTime.min.toLocaleDateString(
            "en-En",
            options
          )}&end=${dateTime.max.toLocaleDateString("en-En", options)}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();

        setOrderData(result);
        setData(result);
        setIsChange(false);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh, id, isChange]);

  const handleResultExcel = async (id)=>{
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Orders/osf?id=${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="content">
      {loading && <p>...Yüklənir</p>}
      {error && (
        <p className="text-danger">Gözlənilməz xəta baş verdi: {error}</p>
      )}
      {orderData && (
        <Fragment>
          <div className="d-flex flex-wrap align-items-center pb-3">
            <h1 className="m-0 mr-4">{orderData.name} Sifarişləri</h1>
            <Button onClick={() => setOrderModal(!orderModal)} color="info">
              Fakturanı yüklə
            </Button>
            <OrderModal
              modal={orderModal}
              setModal={setOrderModal}
              id={orderData.id}
              setLoading={setLoading}
              setError={setError}
            />
            <Button
              onClick={() => setFilterModal(!filterModal)}
              color="warning"
            >
              Filtrlə
            </Button>
            <FilterModal
              modal={filterModal}
              setModal={setFilterModal}
              setIsFiltered={setIsFiltered}
              isFiltered={isFiltered}
              setOrderData={setOrderData}
              dateTime={dateTime}
              setDateTime={setDateTime}
              setIsChange={setIsChange}
              data={data}
            />
          </div>
          <Accordion data-bs-theme="dark" defaultActiveKey="0" flush>
            {orderData.orders &&
              orderData.orders.map((order, key) => {
                return (
                  <Accordion.Item key={key} eventKey={key}>
                    <Accordion.Header className="">
                      Tarix: {order.date.split("T")[0]} & {}
                      {order.date.split("T")[1].substring(0, 5)} (Cəmi:{" "}
                      {order.total.toFixed(2)}
                      ₼)
                    </Accordion.Header>
                    <Accordion.Body>
                      <Button
                        className="btn btn-primary"
                        onClick={() => handleResultExcel(order.id)}
                      >
                        Fakturanı yüklə
                      </Button>
                      <Delete
                        type="Orders"
                        id={order.id}
                        setRefresh={setRefresh}
                        message={`Bu sifariş silinəcək!`}
                        setIsChange={setIsChange}
                      />
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Məhsulun adı</th>
                            <th>Barkod</th>
                            <th>Qiyməti (kq,ədəd)</th>
                            <th>Miqdarı</th>
                            <th>Cəmi</th>
                            <th>Qeyd</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.orderItems &&
                            order.orderItems.map((item, itemKey) => {
                              return (
                                <tr key={itemKey}>
                                  <td>{item.productStockName}</td>
                                  <td>{item.productBarcode}</td>
                                  <td>{item.productPrice}</td>
                                  <td>{item.value}</td>
                                  <td>
                                    {(item.productPrice * item.value).toFixed(
                                      2
                                    )}
                                  </td>
                                  <td>
                                    {item.note || item.note.length > 0
                                      ? item.note
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </Fragment>
      )}
    </div>
  );
};

const OrderModal = ({ modal, setModal, id}) => {
  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/Orders/iosf?start=${dateTime.min.toLocaleDateString(
          "az-Az",
          options
        )}&end=${dateTime.max.toLocaleDateString("az-Az", options)}&id=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      setModal(!modal);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(!modal)}>Fakturanı yüklə</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="min">Tarixdən</Label>
          <Input
            className="text-dark"
            value={dateTime.min.toISOString().split("T")[0]}
            id="min"
            name="min"
            placeholder="Tarixdən"
            type="date"
            onChange={InputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="max">Tarixə</Label>
          <Input
            onChange={InputChange}
            className="text-dark"
            value={dateTime.max.toISOString().split("T")[0]}
            id="max"
            name="max"
            placeholder="Tarixdən"
            type="date"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-info" onClick={downloadExcel} disabled={loading}>
          Yüklə
        </button>{" "}
        <Button color="secondary" onClick={() => setModal(false)}>
          İmtina et
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FilterModal = ({
  modal,
  setModal,
  setIsFiltered,
  isFiltered,
  setOrderData,
  dateTime,
  setDateTime,
  setIsChange,
  data,
}) => {
  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };

  const Filter = () => {
    setIsFiltered(true);
    setIsChange(true);
    setModal(!modal);
  };

  const Clear = () => {
    if (isFiltered) {
      setOrderData(data);
      setIsFiltered(false);
    }
    setModal(!modal);
  };
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(!modal)}>
        Sifarişləri Filtrlə
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="min">Tarixdən</Label>
          <Input
            className="text-dark"
            value={dateTime.min.toISOString().split("T")[0]}
            id="min"
            name="min"
            placeholder="Tarixdən"
            type="date"
            onChange={InputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="max">Tarixə</Label>
          <Input
            onChange={InputChange}
            className="text-dark"
            value={dateTime.max.toISOString().split("T")[0]}
            id="max"
            name="max"
            placeholder="Tarixdən"
            type="date"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-info" onClick={Filter}>
          Filtrlə
        </button>{" "}
        <Button color="secondary" onClick={Clear}>
          {isFiltered ? "Təmizlə" : "İmtina Et"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ShopOrders;
