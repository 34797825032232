import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap/es";

const CreateProduct = () => {
  const [categoryData, setCategoryData] = useState(null);
  const [error, setError] = useState(null);
  const [productData, setProductData] = useState({
    stockName: "",
    stockCode: "",
    barCode: "",
    price: null,
    pakageKq: null,
    categoryId: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const CreateProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Products/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(productData),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/products");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    if ((name == "price" || name == "pakageKq") && isNaN(value)) return;
    setProductData((prevProductData) => ({
      ...prevProductData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        setCategoryData(result);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="content">
      <Form onSubmit={CreateProduct}>
        {error && <p className="text-danger">{error}</p>}
        <Label htmlFor="stockName">Məhsul Adı*</Label>
        <Input
          required
          value={productData.stockName}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="stockName"
          id="stockName"
          placeholder="Məhsul adını daxil et"
        />

        <Label htmlFor="stockCode">Stok Kodu*</Label>
        <Input
          required
          value={productData.stockCode}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="stockCode"
          id="stockCode"
          placeholder="Stok kodunu daxil et"
        />

        <Label htmlFor="barCode">Barkod*</Label>
        <Input
          required
          value={productData.barCode}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="barCode"
          id="barCodeProduct"
          placeholder="Barkodu daxil et"
        />

        <Label htmlFor="pakageKq">1 Bağlamadakı Məhsulun Çəkisi(kq)</Label>
        <Input
          value={productData.pakageKq}
          onChange={InputChange}
          className="w-50"
          inputMode="decimal"
          type="text"
          name="pakageKq"
          id="pakageKq"
          placeholder="Çəkini(kq) daxil et"
        />

        <Label htmlFor="price">Qiyməti</Label>
        <Input
          inputMode="decimal"
          value={productData.price}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="price"
          id="price"
          placeholder="Qiyməti daxil et"
        />

        <Label htmlFor="category">Kateqoriyalar*</Label>
        <select
          required
          value={productData.categoryId}
          onChange={InputChange}
          className="form-control w-25"
          name="categoryId"
        >
          <option value="">Kateqoriya seçin</option>
          {categoryData &&
            categoryData.map((cat, key) => {
              return (
                <option key={key} value={cat.categoryId}>
                  {cat.categoryName}
                </option>
              );
            })}
        </select>
        <Button type="submit" disabled={loading}>
          Yarat
        </Button>
      </Form>
    </div>
  );
};

export default CreateProduct;
