import { useEffect, useState } from "react";
import { apiKey } from "BaseApi/ApiKey";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { string } from "prop-types";

const AreaManagerAddSAndC = () => {
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [shop, setShop] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({
    default: false,
    category: false,
    shop: false,
  });
  const [isCheck, setIsCheck] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [dataError, setDataError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading((prev) => ({
          ...prev,
          category: true,
        }));
        setRefresh(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setDataError(await response.text());
        }

        const result = await response.json();
        setCategories(result);
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading((prev) => ({
          ...prev,
          category: false,
        }));
        setRefresh(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading((prev) => ({
          ...prev,
          default: true,
        }));
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/AreaManagers/getById?id=${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        const ids = result.managerCategories.map(
          (category) => category.categoryId
        );
        let newCat = [];
        let isExist = false;

        for (let i = 0; i < categories.length; i++) {
          isExist = false;
          for (let j = 0; j < ids.length; j++) {
            if (categories[i].categoryId === ids[j]) {
              isExist = true;
            }
          }
          if (!isExist) {
            newCat.push(categories[i]);
          }
        }
        setCategories(newCat);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading((prev) => ({
          ...prev,
          default: false,
        }));
      }
    };

    fetchData();
  }, [id, refresh]);

  const handleUpdateCategoryAreaManagers = async (e) => {
    e.preventDefault();
    setLoading((prev) => ({
      ...prev,
      category: true,
    }));

    try {
      const jwtToken = Cookies.get("token");

      const response = await fetch(`${apiKey}/nam/areaManagers/addCat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          managerId: id,
          categoryIds: category,
        }),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/areamanagers");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading((prev) => ({
        ...prev,
        category: false,
      }));
    }
  };

  const handleUpdateShopAreaManagers = async (e) => {
    e.preventDefault();
    setLoading((prev) => ({
      ...prev,
      shop: true,
    }));

    try {
      const jwtToken = Cookies.get("token");

      const response = await fetch(
        `${apiKey}/nam/areaManagers/addShop?managerId=${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(shop),
        }
      );

      console.log(response);

      if (!response.ok) {
        setError(await response.text());
        return;
      }

      navigate("/admin/areamanagers");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading((prev) => ({
        ...prev,
        shop: false,
      }));
    }
  };

  const handleChangeCategorySelect = (categoryId) => {
    let isExist = category.includes(categoryId);
    let newCategory = [];

    if (!isExist) {
      setCategory([...category, String(categoryId)]);
    } else {
      category.forEach((value) => {
        if (value !== categoryId) {
          newCategory.push(value);
        }
      });

      setCategory(newCategory);
    }
  };

  const handleAllCategoryChangeSelect = () => {
    const newArr = [];
    categories.forEach((item) => {
      newArr.push(item.categoryId);
    });
    setCategory(newArr);
  };

  const handleChangeShopSelect = (depoNos) => {
    let newShop = depoNos.split(",");

    newShop.forEach((item) => {
      setIsCheck(null);

      if (!Number(item)) {
        setIsCheck("Daxil edilən dəyər düzgün deyil!");

        if (newShop.length == 1 && item === "") {
          setIsCheck(null);
        }
      }
    });

    setShop(newShop);
  };

  return (
    <div className="content">
      {loading.default && <p className="p-3 text-warning">...Yüklənir</p>}
      {error && <p className="p-3 text-danger">Xəta baş verdi: {error}</p>}
      <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-2">
        <div className="bg-info text-white py-4 px-4 rounded position-relative">
          <p
            className="mb-3"
            style={{ fontSize: "24px", fontWeight: "400", color: "white" }}
          >
            Kategoriya Elave Et
          </p>
          <FormGroup>
            <Label htmlFor="categoryId">Kateqoriyalar</Label>
            <select
              value={category}
              onChange={(e) => handleChangeCategorySelect(e.target.value)}
              className="form-control text-black customSLc"
              name="categoryId"
              multiple
            >
              <option
                style={{
                  cursor: "pointer",
                  fontWeight: "600",
                  color: "red",
                }}
                value=""
                onClick={() => {
                  setCategory([]);
                }}
              >
                Sıfırla
              </option>
              <option
                style={{
                  cursor: "pointer",
                  fontWeight: "600",
                  color: "black",
                }}
                value=""
                onClick={() => {
                  handleAllCategoryChangeSelect();
                }}
              >
                Hamisini Sec
              </option>
              {categories &&
                categories.map((cat, key) => {
                  return (
                    <option
                      style={{ color: "black" }}
                      key={key}
                      value={cat.categoryId}
                    >
                      {cat.categoryName}
                    </option>
                  );
                })}
            </select>
          </FormGroup>
          <div className="d-flex gap-2">
            <Button
              disabled={loading.category}
              onClick={(e) => handleUpdateCategoryAreaManagers(e)}
            >
              Yadda Saxla
            </Button>
          </div>
        </div>
        <div className="bg-info text-white py-4 px-4 rounded position-relative">
          <p
            className="mb-3"
            style={{ fontSize: "24px", fontWeight: "400", color: "white" }}
          >
            Magaza Elave Et
          </p>
          <FormGroup>
            <Label htmlFor="depoNo">DepoNo</Label>
            <Input
              className="text-dark customInp"
              id="depoNo"
              name="depoNo"
              placeholder="DepoNo Daxil edin"
              type="text"
              onChange={(e) => handleChangeShopSelect(e.target.value)}
            />
            {isCheck ? <p className="m-0 pt-2 text-danger">{isCheck}</p> : null}
          </FormGroup>
          <div className="d-flex gap-2">
            <Button
              disabled={loading.shop}
              onClick={(e) => handleUpdateShopAreaManagers(e)}
            >
              Yadda Saxla
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaManagerAddSAndC;
