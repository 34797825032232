import { apiKey } from 'BaseApi/ApiKey'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input, Label } from 'reactstrap'

const UpdateCategory = () => {
  const { id } = useParams()
  const [error, setError] = useState(null)
  const [category, setCategory] = useState(null)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get('token')

        const response = await fetch(`${apiKey}/nam/categories/getById${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
          },
        }

        );
        if (!response.ok) {
          setError(await response.text())
          return
        }

        const result = await response.json();
        setCategory(result);
      } catch (error) {
        console.log(error)
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const UpdateCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const jwtToken = Cookies.get('token');
      
      const response = await fetch(`${apiKey}/nam/Categories/update?id=${id}&name=${category.name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`,
        },
        body : JSON.stringify(category)
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/categories");
    } catch (error) {
      console.log(error)
      setError("Gözlənilməz xəta baş verdi");
    }
    finally {
      setLoading(false);
    }
  }

  const InputChange = (e) => {
    const {name,value} = e.target
    setCategory((prev)=>({
      ...prev,
      [name] : value
    }));
  }
  return (
    <div className='content'>
       {error && <p className='text-danger'>{error}</p>}
       {loading && <p className='p-3 text-warning'>...Yüklənir</p>}
      {
        category &&
        <Form onSubmit={UpdateCategory}>
          <Label htmlFor="name">Kateqoriya Adı*</Label>
          <Input required value={category.name} onChange={InputChange} className='w-50' type="text" name='name' id='name' placeholder='Kateqoriya adını daxil et' />
          <Button type='submit' disabled={loading}>Yenilə</Button>
        </Form>
      }
    </div>
  )
}

export default UpdateCategory