import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";

const ProductDetail = () => {
  const [data, setData] = useState({
    columns: [
      {
        label: "Magazanin adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Mal Qaligi",
        field: "residue",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const { id } = useParams();
  const [error, setError] = useState(null);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalResidue, setTotalResidue] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/Products/detail${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        const rowsData = result.shops.map((shop) => ({
          shopName: shop.shopName,
          residue: shop.residue,
        }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));

        let sum = 0;
        let shop = result.shops;
        console.log(shop);

        shop.forEach((item) => {
          sum+=item.residue;
        })
        setTotalResidue(sum);
        setProduct(result);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    product && (
      <Fragment>
        {loading && <p className="p-3 text-warning">...Yüklənir</p>}
        {error && <p className="p-3 text-danger">Xəta baş verdi: {error}</p>}
        <div className="content">
          <Table
            data-bs-theme="dark"
            className="tablesorter overflow-auto mb-5"
          >
            <thead className="text-primary">
              <tr>
                <th>Stok kodu</th>
                <th>Bağlama/Kq</th>
                <th>Qiymeti</th>
                <th>Umumi Mal Qaligi</th>
              </tr>
            </thead>
            <tbody>
              {
                <tr>
                  <td>{product.data.stockCode}</td>
                  <td>{product.data.pakageKq}</td>
                  <td>
                    {product.data.prices.length > 0 &&
                      (product.data.prices
                        ? product.data.prices[
                            product.data.prices.length - 1
                          ].price.toFixed(1) + "₼"
                        : "-")}
                  </td>
                  <td>{totalResidue.toFixed(2)}</td>
                </tr>
              }
            </tbody>
          </Table>
          {product.data.price && (
            <>
              <div className="d-flex flex-wrap align-items-center pb-3">
                <h1 className="m-0 mr-4">
                  {product.data.stockName} Keçmiş Qiymətləri
                </h1>
              </div>
              <Table data-bs-theme="dark" className="tablesorter overflow-auto">
                <thead className="text-primary">
                  <tr>
                    <th>Məhsulun keçmiş qiyməti</th>
                    <th>Qiymətin dəyişdirilmə tarixi</th>
                  </tr>
                </thead>
                <tbody>
                  {product.data.prices &&
                    product.data.prices.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.price.toFixed(1)} ₼</td>
                          <td>{item.date.split("T")[0]}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          )}
          <div className="d-flex flex-wrap align-items-center pb-3 mt-5">
            <h1 className="m-0 mr-4">Magazalarda Mal Qaligi</h1>
          </div>
          {loading ? (
            <p className="p-3 text-warning">...Yüklənir</p>
          ) : error ? (
            <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
          ) : (
            <MDBDataTable
              data-bs-theme="dark"
              responsiveMd
              striped
              bordered
              hover
              searchLabel="Axtarış"
              paginationLabel={["Əvvəl", "Növbəti"]}
              data={data}
              btn="primary"
            />
          )}
        </div>
      </Fragment>
    )
  );
};

export default ProductDetail;
