import React, { Fragment, useState } from "react";
import Cookies from "js-cookie";
import { FileInput, Label } from "flowbite-react";
import { apiKey } from "BaseApi/ApiKey";
import { jwtDecode } from "jwt-decode";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";

const message1 = `Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! Və Bütun sətrlərdə ancaq Məhsullar olmalıdır
1 (A) - Məhsulun Kateqoriyası
2 (B) - Məhsulun Stok kodu
3 (C) - Məhsulun Adı
4 (D) - Məhsulun barkodu
5 (E) - Məhsulun qiyməti
6 (F) - Əgər varsa Məhsulun Qutu ilə Çəkisi`;

const message2 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır !
1 (A) - Məhsulun Barkodu
2 (B) - Məhsulun Adı
3 (C) - Məhsulun Yeni Qiyməti
`;

const message3 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! 
1 (A) - Məhsulun Barkodu
2 (B) - Mal Qalığı (kg/ədəd)
3 (C) - Mağazanın Depo Nömrəsi
`;

const message4 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! Və Bütun sətrlərdə ancaq Məhsullar olmalıdır
1 (A) - Məhsulun Barkodu
2 (B) - Məhsulun Vitrin Qiyməti
3 (C) - Mağazanın Depo Nömrəsi
`;

const message5 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! Və Bütun sətrlərdə ancaq Məhsullar olmalıdır
1 (A) - Məhsulun Barkodu
2 (B) - Məhsulun Endirimli Qiyməti
3 (C) - Mağazanın Depo Nömrəsi
4 (D) - Kompaniyanın Başlama Tarixi
5 (E) - Kompaniyanın Bitmə Tarixi
`;

const message6 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! 
1 (A) - Tarix
2 (B) - Mağaza Depo No
3 (C) - Mağaza Adı
4 (D) - Stok Kodu
5 (E) - Stok Adı
6 (F) - Satış Miqdarı
7 (G) - Maya Dəyəri
`;

const message7 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! 
1 (A) - Tarix
2 (B) - Mağaza Depo No
3 (C) - Barkod
4 (D) - Miqdar
5 (E) - ANA DÖVİZ TUTAR
`;

const message8 = `
Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! 
1 (A) - Tarix
2 (B) - Mağaza Depo No
3 (C) - Mağaza Adı
4 (D) - Stok Kodu
5 (E) - Stok Adı
6 (F) - Satış Miqdarı
7 (G) - Satış Məbləği
8 (H) - ALIS QIYMETINE GORE MEBLEG
`;

const message9 = `Yerləşdirləcək Excel faylının işərisində Sütunlar mütləq Bu şəkildə olmalıdır ! 
1 (A) - Tarix
2 (B) - Mal BarKodu
3 (C) - Malın Stok adı 
4 (D) - Kq / Ədəd
5 (E) - Malın qiyməti
6 (F) - Ümumi qiymet  `;

const adminId = "c54ca496-cec3-4a1f-b229-97f3893bf533";

const Downloads = () => {
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState({
    insertProduct: null,
    dailyPrices: null,
    residue: null,
    displayPrice: null,
    discountedPrice: null,
    wasteProduct: null,
    countingResult: null,
    salesResult: null,
    countingResultDelete: null,
    salesResultDelete: null,
    wasteProductDelete: null,
    importPurchase: null,
  });
  const [error, setError] = useState({
    insertProduct: null,
    dailyPrices: null,
    residue: null,
    displayPrice: null,
    discountedPrice: null,
    wasteProduct: null,
    countingResult: null,
    salesResult: null,
    countingResultDelete: null,
    salesResultDelete: null,
    wasteProductDelete: null,
    importPurchase: null,
  });
  const [success, setSuccess] = useState({
    insertProduct: false,
    dailyPrices: false,
    residue: null,
    displayPrice: null,
    discountedPrice: null,
    wasteProduct: null,
    countingResult: null,
    salesResult: null,
    countingResultDelete: null,
    salesResultDelete: null,
    wasteProductDelete: null,
    importPurchase: null,
  });

  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };

  const userId =
    Cookies.get("token") &&
    jwtDecode(Cookies.get("token"))[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ];

  const handleFileChange = (e) => {
    const { name } = e.target;
    setFiles((prev) => ({
      ...prev,
      [name]: e.target.files[0],
    }));
  };

  const handleUpload = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Products/${type}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }
      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadProductRest = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    console.log(apiKey);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Shops/updateQaliq`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadDisplayPrices = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Excels/displayPrices`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadDiscountedPrices = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Excels/discountedPrices`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      console.log(response);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadWasteProducts = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Excels/wasteproducts`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      console.log(response);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadCountingResult = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Excels/countingresult`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      console.log(response);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadSalesResult = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Excels/salesresult`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      console.log(response);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadImportPurchase = async (type) => {
    const formData = new FormData();
    formData.append("file", files[type]);
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Excels/importPurchase`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCounting = async (type) => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/deletecounting?start=${dateTime.min.toLocaleDateString(
          "en-US",
          options
        )}&end=${dateTime.max.toLocaleDateString("en-US", options)}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSalesResult = async (type) => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/deletesale?start=${dateTime.min.toLocaleDateString(
          "en-US",
          options
        )}&end=${dateTime.max.toLocaleDateString("en-US", options)}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteWaste = async (type) => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/deleteWaste?start=${dateTime.min.toLocaleDateString(
          "en-US",
          options
        )}&end=${dateTime.max.toLocaleDateString("en-US", options)}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-4">
        {adminId === userId ? (
          <Fragment>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info header={"Qiymət yükləmə qaydası"} message={message2} />
              <div className="mb-2 block">
                <Label
                  htmlFor="dailyPrices"
                  style={{ fontSize: "1.8em" }}
                  value="Günlük Qiymətləri Yüklə"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="dailyPrices"
                name="dailyPrices"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.dailyPrices || loading}
                className="w-100"
                onClick={() => handleUpload("dailyPrices")}
              >
                Yüklə
              </Button>
              {error.dailyPrices && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.dailyPrices}
                </p>
              )}
              {success.dailyPrices && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info header={"Məhsul yükləmə qaydası"} message={message1} />
              <div className="mb-2 block">
                <Label
                  htmlFor="insertProduct"
                  style={{ fontSize: "1.8em" }}
                  value="Məhsulları Yüklə"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="insertProduct"
                name="insertProduct"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.insertProduct || loading}
                className="w-100"
                onClick={() => handleUpload("insertProduct")}
              >
                Yüklə
              </Button>
              {error.insertProduct && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.insertProduct}
                </p>
              )}
              {success.insertProduct && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info header={"Mal Qaligi Yukleme Qaydasi"} message={message3} />
              <div className="mb-2 block">
                <Label
                  htmlFor="residue"
                  style={{ fontSize: "1.8em" }}
                  value="Mal Qaligini Yukle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="residue"
                name="residue"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.residue || loading}
                className="w-100"
                onClick={() => handleUploadProductRest("residue")}
              >
                Yüklə
              </Button>
              {error.residue && (
                <p className="bg-danger rounded p-1">Xəta: {error.residue}</p>
              )}
              {success.residue && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info
                header={"Məhsulların Vitrin Qiymətlerinin Yukleme Qaydasi"}
                message={message4}
              />
              <div className="mb-2 block">
                <Label
                  htmlFor="displayPrice"
                  style={{ fontSize: "1.8em" }}
                  value="Vitrin Qiymetleri Yukle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="displayPrice"
                name="displayPrice"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.displayPrice || loading}
                className="w-100"
                onClick={() => handleUploadDisplayPrices("displayPrice")}
              >
                Yüklə
              </Button>
              {error.displayPrice && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.displayPrice}
                </p>
              )}
              {success.displayPrice && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info
                header={"Məhsulların Endirimli Qiymətlerinin Yukleme Qaydasi"}
                message={message5}
              />
              <div className="mb-2 block">
                <Label
                  htmlFor="discountedPrice"
                  style={{ fontSize: "1.8em" }}
                  value="Endirim Qiymetleri Yukle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="discountedPrice"
                name="discountedPrice"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.discountedPrice || loading}
                className="w-100"
                onClick={() => handleUploadDiscountedPrices("discountedPrice")}
              >
                Yüklə
              </Button>
              {error.discountedPrice && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.discountedPrice}
                </p>
              )}
              {success.discountedPrice && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info
                header={"Tullantı Məhsullarən Yükləmə Qaydası"}
                message={message6}
              />
              <div className="mb-2 block">
                <Label
                  htmlFor="wasteProduct"
                  style={{ fontSize: "1.8em" }}
                  value="Tullantı Məhsullari Yükle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="wasteProduct"
                name="wasteProduct"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.wasteProduct || loading}
                className="w-100"
                onClick={() => handleUploadWasteProducts("wasteProduct")}
              >
                Yüklə
              </Button>
              {error.wasteProduct && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.wasteProduct}
                </p>
              )}
              {success.wasteProduct && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info
                header={"Sayım Nəticəsini Yükləmə Qaydası"}
                message={message7}
              />
              <div className="mb-2 block">
                <Label
                  htmlFor="countingResult"
                  style={{ fontSize: "1.8em" }}
                  value="Sayım Nəticələri Yükle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="countingResult"
                name="countingResult"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.countingResult || loading}
                className="w-100"
                onClick={() => handleUploadCountingResult("countingResult")}
              >
                Yüklə
              </Button>
              {error.countingResult && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.countingResult}
                </p>
              )}
              {success.countingResult && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info
                header={"Satış Nəticəsini Yükləmə Qaydası"}
                message={message8}
              />
              <div className="mb-2 block">
                <Label
                  htmlFor="salesResult"
                  style={{ fontSize: "1.8em" }}
                  value="Satış Nəticələri Yükle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="salesResult"
                name="salesResult"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.salesResult || loading}
                className="w-100"
                onClick={() => handleUploadSalesResult("salesResult")}
              >
                Yüklə
              </Button>
              {error.salesResult && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.salesResult}
                </p>
              )}
              {success.salesResult && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <Info header={"İdxal Alış Yükləmə Qaydası"} message={message9} />
              <div className="mb-2 block">
                <Label
                  htmlFor="importPurchase"
                  style={{ fontSize: "1.8em" }}
                  value="İdxal Alış Yükle"
                  className="text-lg"
                />
              </div>
              <FileInput
                id="importPurchase"
                name="importPurchase"
                className="mb-3"
                onChange={handleFileChange}
              />
              <Button
                disabled={!files.importPurchase || loading}
                className="w-100"
                onClick={() => handleUploadImportPurchase("importPurchase")}
              >
                Yüklə
              </Button>
              {error.importPurchase && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.importPurchase}
                </p>
              )}
              {success.importPurchase && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Yükləndi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative ">
              <div className="mb-2 block">
                <Label
                  htmlFor="countingResultDelete"
                  style={{ fontSize: "1.8em" }}
                  value="Sayim Nəticələri Sil"
                  className="text-lg"
                />
              </div>
              <FormGroup>
                <Label htmlFor="min">Tarixdən</Label>
                <Input
                  className="text-dark"
                  value={dateTime.min.toISOString().split("T")[0]}
                  id="min"
                  name="min"
                  placeholder="Tarixdən"
                  type="date"
                  onChange={InputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="max">Tarixə</Label>
                <Input
                  onChange={InputChange}
                  className="text-dark"
                  value={dateTime.max.toISOString().split("T")[0]}
                  id="max"
                  name="max"
                  placeholder="Tarixdən"
                  type="date"
                />
              </FormGroup>
              <Button
                disabled={files.countingResultDelete || loading}
                className="w-100"
                onClick={() => handleDeleteCounting("countingResultDelete")}
              >
                Sil
              </Button>
              {error.salesResult && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.countingResultDelete}
                </p>
              )}
              {success.countingResultDelete && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Silindi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <div className="mb-2 block">
                <Label
                  htmlFor="salesResultDelete"
                  style={{ fontSize: "1.8em" }}
                  value="Satış Nəticələri Sil"
                  className="text-lg"
                />
              </div>
              <FormGroup>
                <Label htmlFor="min">Tarixdən</Label>
                <Input
                  className="text-dark"
                  value={dateTime.min.toISOString().split("T")[0]}
                  id="min"
                  name="min"
                  placeholder="Tarixdən"
                  type="date"
                  onChange={InputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="max">Tarixə</Label>
                <Input
                  onChange={InputChange}
                  className="text-dark"
                  value={dateTime.max.toISOString().split("T")[0]}
                  id="max"
                  name="max"
                  placeholder="Tarixdən"
                  type="date"
                />
              </FormGroup>
              <Button
                disabled={files.salesResultDelete || loading}
                className="w-100"
                onClick={() => handleDeleteSalesResult("salesResultDelete")}
              >
                Sil
              </Button>
              {error.salesResultDelete && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.salesResultDelete}
                </p>
              )}
              {success.salesResultDelete && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Silindi
                </p>
              )}
            </div>
            <div className="bg-info text-white py-4 px-4 rounded position-relative">
              <div className="mb-2 block">
                <Label
                  htmlFor="wasteProductDelete"
                  style={{ fontSize: "1.8em" }}
                  value="Tullanti Mehsullari Sil"
                  className="text-lg"
                />
              </div>
              <FormGroup>
                <Label htmlFor="min">Tarixdən</Label>
                <Input
                  className="text-dark"
                  value={dateTime.min.toISOString().split("T")[0]}
                  id="min"
                  name="min"
                  placeholder="Tarixdən"
                  type="date"
                  onChange={InputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="max">Tarixə</Label>
                <Input
                  onChange={InputChange}
                  className="text-dark"
                  value={dateTime.max.toISOString().split("T")[0]}
                  id="max"
                  name="max"
                  placeholder="Tarixdən"
                  type="date"
                />
              </FormGroup>
              <Button
                disabled={files.wasteProductDelete || loading}
                className="w-100"
                onClick={() => handleDeleteWaste("wasteProductDelete")}
              >
                Sil
              </Button>
              {error.wasteProductDelete && (
                <p className="bg-danger rounded p-1">
                  Xəta: {error.wasteProductDelete}
                </p>
              )}
              {success.wasteProductDelete && (
                <p className="bg-success rounded text-dark p-1">
                  Uğurla Silindi
                </p>
              )}
            </div>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </div>
  );
};

const Info = ({ message, header }) => {
  const [modal, setModal] = useState(false);

  return (
    <div className="position-absolute" style={{ top: "10px", right: "15px" }}>
      <Link className="text-dark" onClick={() => setModal(true)}>
        <i className="fa-solid fa-circle-info" />
      </Link>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(!modal)}>{header}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Tamam
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Downloads;
