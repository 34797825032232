import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AreaManagerDetail = () => {
  const [shop, setShop] = useState({
    columns: [
      {
        label: "Mağazanın adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Konteyner kodu",
        field: "depoNo",
        sort: "asc",
      },
      {
        label: "Telefon nömrəsi",
        field: "phoneNumber",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [categories, setCategories] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/AreaManagers/getById?id=${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        const rowsData = result.shops.map((shop) => ({
          shopName: shop.name,
          depoNo: shop.tankNo,
          phoneNumber: shop.phoneNumber,
        }));
        setShop((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));

        const allCategory = result.managerCategories.map(
          (category) => category.categoryName
        );
        setCategories(allCategory);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="content">
      <Fragment>
        <div className="content">
          {loading ? (
            <p className="p-3 text-warning">...Yüklənir</p>
          ) : error ? (
            <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
          ) : (
            <>
              {shop && (
                <>
                  <div className="d-flex flex-wrap align-items-center pb-3">
                    <h2 className="m-0 mr-4">Mağaza Siyahısı</h2>
                  </div>
                  <MDBDataTable
                    data-bs-theme="dark"
                    responsiveMd
                    striped
                    bordered
                    hover
                    searchLabel="Axtarış"
                    paginationLabel={["Əvvəl", "Növbəti"]}
                    data={shop}
                  />
                </>
              )}
              {categories && (
                <>
                  <div className="d-flex flex-wrap align-items-center pb-3">
                    <h2 className="m-0 mr-4">Kateqoriya Siyahısı</h2>
                  </div>
                  <div>
                    <ol className="ps-3">
                      {categories.map((cat, key) => {
                        return (
                          <li
                            className="text-info"
                            style={{ fontSize: "18px" }}
                            key={key}
                          >
                            {cat}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default AreaManagerDetail;
