import { apiKey } from "BaseApi/ApiKey";
import { Label } from "flowbite-react";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

function Dashboard() {
  const [data, setData] = useState({
    columns: [
      {
        label: "Mağazanın adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Sifaris Miqdari",
        field: "orderQuantity",
        sort: "asc",
      },
      {
        label: "Sifaris Deyeri",
        field: "orderValue",
        sort: "asc",
      },
      {
        label: "Senedli Sifaris",
        field: "docCount",
        sort: "asc",
      },
      {
        label: "Sifaris Edilmis Kategoriyalar",
        field: "orderedCategories",
        sort: "asc",
      },
      {
        label: "Digər",
        field: "other",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [dataOrderTable, setDataOrderTable] = useState({
    columns: [
      {
        label: "Mehsulun adı",
        field: "productName",
        sort: "asc",
      },
      {
        label: "BarCode",
        field: "barCode",
        sort: "asc",
      },
      {
        label: "Alış",
        field: "alis",
        sort: "asc",
      },
      {
        label: "Cem Sifaris/Kq",
        field: "totalOrder",
        sort: "asc",
      },
      {
        label: "Anbar Qaliq",
        field: "depoKq",
        sort: "asc",
      },
      {
        label: "Alinacaq Miqdar",
        field: "endKq",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [error, setError] = useState({
    insertProduct: null,
    dailyPrices: null,
  });

  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState();
  const [categoryFac, setCategoryFac] = useState("");
  const [categoryPro, setCategoryPro] = useState(null);
  const [report, setReport] = useState(null);

  const [datas, setDatas] = useState(null);
  const [dataError, setDataError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setDataError(await response.text());
        }

        const result = await response.json();
        setCategories(result);
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });

  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const downloadExcelInterval = () => {
    const downloadLink = `${apiKey}/nam/Orders/IntervalFacture?start=${dateTime.min.toLocaleDateString(
      "en-US",
      options
    )}&end=${dateTime.max.toLocaleDateString(
      "en-US",
      options
    )}&categoryId=${category}&wantExcel=true`;
    window.location.href = downloadLink;
  };

  const downloadExcel = () => {
    const downloadLink = `${apiKey}/nam/Orders/iasf?start=${dateTime.min.toLocaleDateString(
      "en-US",
      options
    )}&end=${dateTime.max.toLocaleDateString(
      "en-US",
      options
    )}&categoryId=${categoryFac}&wantExcel=true`;
    window.location.href = downloadLink;
  };

  const downloadExcelProductReport = async () => {
    try {
      setDataLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/productReport?start=${dateTime.min.toLocaleDateString(
          "en-En",
          options
        )}&end=${dateTime.max.toLocaleDateString(
          "en-En",
          options
        )}&categoryId=${categoryPro}&wantExcel=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setDataLoading(false);
    }
  };

  const showDatas = async (no) => {
    let fetchLink = "";
    if (no === 1)
      fetchLink = `${apiKey}/nam/data/productReport?start=${dateTime.min.toLocaleDateString(
        "en-En",
        options
      )}&end=${dateTime.max.toLocaleDateString(
        "en-En",
        options
      )}&categoryId=${categoryPro}`;
    else if (no === 2)
      fetchLink = `${apiKey}/nam/Orders/IntervalFacture?start=${dateTime.min.toLocaleDateString(
        "en-En",
        options
      )}&end=${dateTime.max.toLocaleDateString(
        "en-En",
        options
      )}&categoryId=${category}&wantExcel=false`;
    try {
      setDataLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(fetchLink, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        setDataError(await response.text());
        return;
      }

      const result = await response.json();

      if (no === 2) {
        const rowsData = result.data.facture.map((shop) => ({
          shopName: shop.name,
          orderQuantity: shop.value,
          orderValue: shop.price.toFixed(2),
          orderedCategories: shop.categories,
          docCount: shop.docCount,
          other: (
            <Link
              className="btn btn-warning"
              to={{
                pathname: `/admin/shops/orders/${shop.shopId}`,
                search: `?dateMin=${dateTime.min}&dateMax=${dateTime.max}`,
              }}
            >
              Sifarişlər
            </Link>
          ),
        }));

        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
        setReport(2);
      } else if (no === 1) {
        const rowsData = result.data.map((shop) => ({
          productName: shop.stockName,
          barCode: shop.barCode,
          alis: shop.alis,
          totalOrder: shop.totalOrder,
          depoKq: shop.depoKq,
          endKq: shop.endKq.toFixed(2),
        }));

        setDataOrderTable((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));

        setReport(1);
      }

      setDataError(null);
      setDatas(result);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="content">
        {loading && <p>... Yuklenir</p>}
        <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-2">
          <div className="bg-info text-white py-4 px-4 rounded position-relative">
            <div className="mb-2 block">
              <Label
                htmlFor="ihes"
                style={{ fontSize: "1.8em" }}
                value="Mağazaların İnterval Hesabatı"
                className="text-lg"
              />
            </div>
            <FormGroup>
              <Label htmlFor="min">Tarixdən</Label>
              <Input
                className="text-dark"
                value={dateTime.min.toISOString().split("T")[0]}
                id="min"
                name="min"
                placeholder="Tarixdən"
                type="date"
                onChange={InputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="max">Tarixə</Label>
              <Input
                onChange={InputChange}
                className="text-dark"
                value={dateTime.max.toISOString().split("T")[0]}
                id="max"
                name="max"
                placeholder="Tarixdən"
                type="date"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="categoryId">Kateqoriyalar</Label>
              <select
                defaultValue={category}
                onChange={(e) => setCategory(e.target.value)}
                className="form-control text-black"
                name="categoryId"
              >
                <option value="">Bütün Kateqoriyalar</option>
                {categories &&
                  categories.map((cat, key) => {
                    return (
                      <option key={key} value={cat.categoryId}>
                        {cat.categoryName}
                      </option>
                    );
                  })}
              </select>
            </FormGroup>
            <div className="d-flex gap-2">
              <Button
                disabled={dataLoading}
                className="w-50"
                color="warning"
                onClick={() => showDatas(2)}
              >
                Göstər
              </Button>
              <Button className="w-50" onClick={downloadExcelInterval}>
                Yüklə
              </Button>
            </div>
          </div>
          <div className="bg-info text-white py-4 px-5 rounded position-relative">
            <div className="mb-2 block">
              <Label
                htmlFor="ihes"
                style={{ fontSize: "1.8em" }}
                value="Fakturani yukle"
                className="text-lg"
              />
            </div>
            <FormGroup>
              <Label htmlFor="min">Tarixdən</Label>
              <Input
                className="text-dark"
                value={dateTime.min.toISOString().split("T")[0]}
                id="min"
                name="min"
                placeholder="Tarixdən"
                type="date"
                onChange={InputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="max">Tarixə</Label>
              <Input
                onChange={InputChange}
                className="text-dark"
                value={dateTime.max.toISOString().split("T")[0]}
                id="max"
                name="max"
                placeholder="Tarixdən"
                type="date"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="categoryIdFac">Kateqoriyalar</Label>
              <select
                defaultValue={categoryFac}
                onChange={(e) => setCategoryFac(e.target.value)}
                className="form-control text-black"
                name="categoryIdFac"
              >
                <option value="">Bütün Kateqoriyalar</option>
                {categories &&
                  categories.map((cat, key) => {
                    return (
                      <option key={key} value={cat.categoryId}>
                        {cat.categoryName}
                      </option>
                    );
                  })}
              </select>
            </FormGroup>
            <div className="d-flex gap-2 offset-2">
              <button className="btn btn-secondary" onClick={downloadExcel}>
                Yüklə
              </button>{" "}
            </div>
          </div>
          <div className="bg-info text-white py-4 px-4 rounded position-relative">
            <div className="mb-2 block">
              <Label
                htmlFor="producReport"
                style={{ fontSize: "1.8em" }}
                value="Sifarislerin Umumi Hesabati"
                className="text-lg"
              />
            </div>
            <FormGroup>
              <Label htmlFor="min">Tarixdən</Label>
              <Input
                className="text-dark"
                value={dateTime.min.toISOString().split("T")[0]}
                id="min"
                name="min"
                placeholder="Tarixdən"
                type="date"
                onChange={InputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="max">Tarixə</Label>
              <Input
                onChange={InputChange}
                className="text-dark"
                value={dateTime.max.toISOString().split("T")[0]}
                id="max"
                name="max"
                placeholder="Tarixdən"
                type="date"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="categoryProId">Kateqoriyalar</Label>
              <select
                value={categoryPro}
                onChange={(e) => setCategoryPro(e.target.value)}
                className="form-control text-black"
                name="categoryProId"
                id="categoryProId"
              >
                <option value="" disabled selected>
                  Bütün Kateqoriyalar
                </option>
                {categories &&
                  categories.map((cat, key) => {
                    return (
                      (cat.categoryName === "AZ FRES" ||
                        cat.categoryName === "RAHAT ÇƏRƏZ") && (
                        <option key={key} value={cat.categoryId}>
                          {cat.categoryName}
                        </option>
                      )
                    );
                  })}
              </select>
            </FormGroup>
            <div className="d-flex gap-2">
              <Button
                disabled={dataLoading}
                className="w-50"
                color="warning"
                onClick={() => showDatas(1)}
              >
                Göstər
              </Button>
              <Button className="w-50" onClick={downloadExcelProductReport}>
                Yüklə
              </Button>
            </div>
          </div>
        </div>
        {dataError && <p className="text-danger">{dataError}</p>}
        {dataLoading && <p>...Yüklənir</p>}
        {datas && (
          <Fragment>
            <h3 className="mb-3">
              {datas.data.startDate && datas.data.startDate.split("T")[0]}{" "}
              {datas.data.endDate &&
                ` tarixindən ${datas.data.endDate.split("T")[0]} tarixinədək`}
            </h3>
            {report === 2 && (
              <MDBDataTable
                data-bs-theme="dark"
                responsiveMd
                striped
                bordered
                hover
                searchLabel="Axtarış"
                paginationLabel={["Əvvəl", "Növbəti"]}
                data={data}
              />
            )}
            {report === 1 && (
              <MDBDataTable
                data-bs-theme="dark"
                responsiveMd
                striped
                bordered
                hover
                searchLabel="Axtarış"
                paginationLabel={["Əvvəl", "Növbəti"]}
                data={dataOrderTable}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
export default Dashboard;
