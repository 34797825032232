import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import Pagination from "react-bootstrap/Pagination";
import { CardTitle, Input } from "reactstrap/es";
import Table from "react-bootstrap/Table";

const RestOfTheProduct = () => {
    const { depoNo } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [search, setSearch] = useState(""); 
    const [page, setPage] = useState({
        current: 1,
        last: null,
        firstPages: 4,
        lastPages: 4,
    });


    const pages = Array.from({ length: page.last }, (_, i) => i + 1);

    const displayPages = pages.filter((pagee, index) => {
        if (index < page.firstPages) {
            return true;
        }
        if (index >= page.last - page.lastPages) {
            return true;
        }
        if (pagee === page.current - 1 || pagee === page.current + 1) {
            return true;
        }
        if (pagee === page.current) {
            return true;
        }
        return false;
    });

    const hasEllipsis = (start, end) => {
        const range = Array.from({ length: end - start }, (_, i) => start + i);
        return range.some((page) => !displayPages.includes(page));
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const jwtToken = Cookies.get("token");

          const response = await fetch(
            `${apiKey}/nam/areaManagers/malQaligi?search=${search}&tankNo=${depoNo}&page=${page.current}&limit=10`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          if (!response.ok) {
            setError(await response.text());
            return;
          }

          const result = await response.json();
          setData(result.data);
          setPage((prevData) => ({
            ...prevData,
            last: result.pageCount,
          }));
        } catch (error) {
          console.log(error);
          setError("Gözlənilməz xəta baş verdi");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [page.current, search]);

    return (
      <div className="content">
        <CardTitle className="d-flex justify-content-between align-items-center">
          <h1>Mal Qalığı</h1>
          <Input
            className="w-25"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            id="search"
            name="search"
            placeholder="Axtarış dəyərini daxil edin!"
          ></Input>
        </CardTitle>
        {loading ? (
          <p className="p-3 text-warning">...Yüklənir</p>
        ) : error ? (
          <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
        ) : (
          <>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Mehsulun adi</th>
                  <th>Barkod</th>
                  <th>StockCode</th>
                  <th>Mal Qaligi</th>
                  <th>Tarix</th>
                </tr>
              </thead>
              <tbody>
                {data.map((product) => (
                  <tr key={product.productId}>
                    <td>{product.stockName}</td>
                    <td>{product.barCode}</td>
                    <td>{product.stockCode}</td>
                    <td>{product.malGaligi}</td>
                    <td>{product.tarix.split("T")[0]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination>
              <Pagination.First
                onClick={() =>
                  setPage((prevData) => ({
                    ...prevData,
                    current: 1,
                  }))
                }
              />
              <Pagination.Prev
                onClick={() =>
                  setPage((prevData) => ({
                    ...prevData,
                    current: page.current - 1,
                  }))
                }
              />

              {displayPages.map((pagee, index) => (
                <React.Fragment key={pagee}>
                  {index > 0 &&
                    hasEllipsis(displayPages[index - 1] + 1, pagee) && (
                      <Pagination.Ellipsis disabled key={`ellipsis-${index}`} />
                    )}
                  <Pagination.Item
                    active={pagee === page.current}
                    onClick={() =>
                      setPage((prevData) => ({
                        ...prevData,
                        current: pagee,
                      }))
                    }
                  >
                    {pagee}
                  </Pagination.Item>
                </React.Fragment>
              ))}
              <Pagination.Next
                onClick={() =>
                  setPage((prevData) => ({
                    ...prevData,
                    current: page.current + 1,
                  }))
                }
              />
              <Pagination.Last
                onClick={() =>
                  setPage((prevData) => ({
                    ...prevData,
                    current: page.last,
                  }))
                }
              />
            </Pagination>
          </>
        )}
      </div>
    );
};

export default RestOfTheProduct;
