import { apiKey } from "BaseApi/ApiKey";
import Delete from "components/Actions/Delete";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

const Categories = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                <Link to="/admin/categories/create" className="btn btn-info">
                  Kateqoriya yarat
                </Link>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table data-bs-theme="dark" className="tablesorter overflow-auto">
                <thead className="text-primary">
                  <tr>
                    <th>Kateqoriyanın adı</th>
                    <th>Mehsul sayı</th>
                    <th className="text-center">Digər</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <p className="p-3 text-warning">...Yüklənir</p>}
                  {error && <p className="p-3 text-danger">{error}</p>}
                  {data &&
                    data.map((cat, key) => {
                      return (
                        <tr key={key}>
                          <td>{cat.categoryName}</td>
                          <td>{cat.productCount}</td>
                          <td className="text-center d-flex gap-2 flex-wrap justify-content-center">
                            <Delete
                              setRefresh={setRefresh}
                              type={"categories"}
                              id={cat.categoryId}
                              message={`${cat.categoryName} və ${cat.categoryName} daxilindəki bütün məhsullar silinəcək!`}
                            />
                            <Link
                              className="btn btn-success"
                              to={`/admin/categories/update/${cat.categoryId}`}
                            >
                              <i className="fa-solid fa-pen-to-square" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Categories;
