import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "views/Auth/Login";
import AdminLayout from "layouts/Admin/Admin";
import ShopLayout from "layouts/Shop/Shop";
import AreaManagerLayout from "layouts/AreaManager/AreaManager";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/orders/*" element={<ShopLayout />} />
        <Route path="/manager/*" element={<AreaManagerLayout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
