import Report from 'components/Actions/Report'
import React from 'react'

const AreaManagerReports = () => {
  return <Report />;
}

export default AreaManagerReports


