import { apiKey } from 'BaseApi/ApiKey';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { json, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Label } from 'reactstrap/es';

const CreateCategory = () => {
    const [error,setError] = useState(null)
    const [categoryName,setCategoryName] = useState("")
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false)
    const CreateCategory = async (e)=>{
        e.preventDefault();
        setLoading(true);
        try {
            const jwtToken = Cookies.get('token');

        const response = await fetch(`${apiKey}/nam/Categories/create?name=${categoryName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`,
            },
        });

        if (!response.ok) {
            setError(await response.text());
            return;
        }
        navigate("/admin/categories");
        } catch (error) {
            console.log(error)
            setError("Gözlənilməz xəta baş verdi");    
        }
        finally{
            setLoading(false);
        }
    }

    const InputChange = (e)=>{
        setCategoryName(e.target.value);
    }
  return (
    <div className='content'>
        <Form onSubmit={CreateCategory}>
            {error && <p className='text-danger'>{error}</p>}
            <Label htmlFor="Category">Kateqoriya Adı*</Label>
            <Input required value={categoryName} onChange={InputChange} className='w-50' type="text" name='Category' id='Category' placeholder='Kateqoriya adını daxil et' />
            <Button type='submit' disabled={loading}>Yarat</Button>
        </Form>
    </div>
  )
}

export default CreateCategory