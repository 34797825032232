import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap/es";

const CreateShop = () => {
  const [error, setError] = useState(null);
  const [shopData, setShopData] = useState({
    name: "",
    tankNo: "",
    password: "",
    phoneNumber: "",
    locationLink: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const CreateShop = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/Shops/createShop`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(shopData),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/shops");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setShopData((prevProductData) => ({
      ...prevProductData,
      [name]: value,
    }));
  };
  return (
    <div className="content">
      <Form onSubmit={CreateShop}>
        {error && <p className="text-danger">{error}</p>}
        <Label htmlFor="name">Mağazanın Adı*</Label>
        <Input
          required
          value={shopData.name}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="name"
          id="name"
          placeholder="Mağazanın adını daxil et"
        />

        <Label htmlFor="tankNo">Konteyner Kodu*</Label>
        <Input
          required
          value={shopData.tankNo}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="tankNo"
          id="tankNo"
          placeholder="Konteyner kodunu daxil et"
        />

        <Label htmlFor="password">Hesab Şifrəsi*</Label>
        <Input
          required
          value={shopData.password}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="password"
          id="password"
          placeholder="Şifrəni daxil et"
        />

        <Label htmlFor="phoneNumber">Elaqe Nomresi*</Label>
        <Input
          inputMode="tel"
          required
          value={shopData.phoneNumber}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="Telefon nömrəsini daxil et"
        />

        <Label htmlFor="locationLink">Konum*</Label>
        <Input
          required
          value={shopData.locationLink}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="locationLink"
          id="locationLink"
          placeholder="Konumu daxil et"
        />

        <Button type="submit" disabled={loading}>
          Yarat
        </Button>
      </Form>
    </div>
  );
};

export default CreateShop;
