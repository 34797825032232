import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";

const DiscountedPrice = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [data, setData] = useState({
    columns: [
      {
        label: "Mehsul Adi",
        field: "stockName",
        sort: "asc",
      },
      {
        label: "BarCode",
        field: "barCode",
        sort: "asc",
      },
      {
        label: "Kateqory",
        field: "categoryName",
        sort: "asc",
      },
      {
        label: "Endirim Qiymeti",
        field: "discountedPrice",
        sort: "asc",
      },
      {
        label: "Başlama Tarixi",
        field: "startDate",
        sort: "asc",
      },
      {
        label: "Bitme Tarixi",
        field: "endDate",
        sort: "asc",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/shops/displayprices`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();

        const rowsData = result.discountedPrices.map((item) => ({
          stockName: item.stockName ? item.stockName : "test",
          barCode: item.barCode,
          categoryName: item.categoryName,
          discountedPrice: item.displayPrice, 
          startDate: String(item.startDate).split("T")[0],
          endDate: String(item.endDate).split("T")[0],
        }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  return (
    <div className="content">
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      )}
    </div>
  );
};

export default DiscountedPrice;
