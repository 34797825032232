import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import { InvalidTokenError, jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const Login = (props) => {
  const [loginData, setLoginData] = useState(
    {
      loginId: "",
      password: "",
    },
    []
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loginHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(`${apiKey}/nam/Auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      console.log(response);

      if (!response.ok || response.status == 444) {
        setError(await response.text());
        return;
      }
      const token = await response.json();
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);
      Cookies.set("token", token, { expires: expirationDate, secure: false });
      const encodedToken = jwtDecode(token);
      const role =
        encodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      if (role === "SuperAdmin") navigate("/admin/dashboard");
      else if (role === "Shop") navigate("/orders/orderlist");
      else if (role === "AreaManager") navigate("/manager/order");
    } catch (error) {
      console.log("error: " + error);
      if (error instanceof InvalidTokenError) {
        setError("Şifrə və ya LoginId yanlışdır");
      } else {
        setError("Gözlənilməz xəta baş verdi! Biraz sonra cəhd edin.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <div className="pt-5">
      <Container>
        <Row>
          <Col>
            <Card className="p-5">
              <Button style={{ fontSize: "1.5em" }}>AzFresh Dagitim</Button>
              <CardBody>
                {error && <p className="text-danger">{error}</p>}
                <Form onSubmit={loginHandler}>
                  <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                    <Label htmlFor="loginId" className="mr-sm-2">
                      LoginId
                    </Label>
                    <Input
                      value={loginData.loginId}
                      type="loginId"
                      name="loginId"
                      id="loginId"
                      required
                      autoComplete="loginId"
                      placeholder="LoginId-nizi daxil edin"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                    <Label htmlFor="password" className="mr-sm-2">
                      Şifrə
                    </Label>
                    <Input
                      value={loginData.password}
                      type="password"
                      name="password"
                      id="password"
                      required
                      autoComplete="password"
                      placeholder="Şifrənizi daxil edin"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <Button type="submit" disabled={loading}>
                    Daxil Olun
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
