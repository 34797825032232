import { apiKey } from "BaseApi/ApiKey";
import Delete from "components/Actions/Delete";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CheckLists = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data, setData] = useState({
    columns: [
      {
        label: "Mağazanın adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Konteyner kodu",
        field: "containerCode",
        sort: "asc",
      },
      {
        label: "Telefon nömrəsi",
        field: "phoneNumber",
        sort: "asc",
      },
      {
        label: "Digər",
        field: "other",
        sort: "asc",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/shops/all`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();

        const rowsData = result.map((shop) => ({
          shopName: shop.name,
          containerCode: shop.tankNo,
          phoneNumber: shop.phoneNumber,
          other: (
            <div className="d-flex gap-2 flex-wrap justify-content-center">
              <div className="d-flex gap-2 flex-wrap justify-content-center">
                <Link
                  className="btn btn-warning"
                  to={`/admin/checklists/checklistReport/${shop.tankNo}`}
                >
                  <i className="fa-solid fa-circle-info" />
                </Link>
              </div>
            </div>
          ),
        }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content">
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      )}
    </div>
  );
};

export default CheckLists;
