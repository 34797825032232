import { apiKey } from "BaseApi/ApiKey";
import Delete from "components/Actions/Delete";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardTitle } from "reactstrap";

const AreaManagerList = () => {
  const [data, setData] = useState({
    columns: [
      {
        label: "Adi",
        field: "name",
        sort: "asc",
      },
      {
        label: "Soyadi",
        field: "surName",
        sort: "asc",
      },
      {
        label: "Magaza ve Kateqoriya",
        field: "add",
        sort: "asc",
      },
      {
        label: "Digər",
        field: "other",
        sort: "asc",
      },
    ],
    rows: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/areaManagers/getAllManagers`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        const rowsData = result.map((areaManager) => ({
          name: areaManager.name,
          surName: areaManager.surName,
          add: (
            <Link
              className="btn btn-primary btn-md"
              to={`/admin/areamanagers/updateSC/${areaManager.id}`}
            >
              Əlavə Et
            </Link>
          ),

          other: (
            <div className="d-flex gap-2 flex-wrap justify-content-center">
              <div className="d-flex gap-2 flex-wrap justify-content-center">
                <Delete
                  setRefresh={setRefresh}
                  type="AreaManagers"
                  message={`${areaManager.name} ${areaManager.surName} silinəcək!`}
                  id={areaManager.id}
                />
              </div>
              <div className="d-flex gap-2 flex-wrap justify-content-center">
                <Link
                  className="btn btn-success"
                  to={`/admin/areamanagers/update/${areaManager.id}`}
                >
                  <i className="fa-solid fa-pen-to-square" />
                </Link>
                <Link
                  className="btn btn-info"
                  to={`/admin/areamanagers/detail/${areaManager.id}`}
                >
                  <i className="fa-solid fa-circle-info"></i>
                </Link>
              </div>
            </div>
          ),
        }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  return (
    <div className="content">
      <CardTitle tag="h4">
        <Link to="/admin/areamanagers/create" className="btn btn-info">
          Şöbə Müdiri yarat
        </Link>
        <Link
          className="btn btn-danger"
          to={`/admin/areamanagers/deleteShop`}
        >
          Magaza Sil
        </Link>
        <Link
          className="btn btn-danger btn"
          to={`/admin/areamanagers/deleteCategory`}
        >
          Kateqoriya Sil
        </Link>
      </CardTitle>
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      )}
    </div>
  );
};

export default AreaManagerList;
