import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";

const DisplayPrices = () => {
  const { depoNo } = useParams();
  const [error, setError] = useState(null);
  const [displayPrices, setDisplayPrices] = useState({
    columns: [
      {
        label: "Mehsul Adi",
        field: "stockName",
        sort: "asc",
      },
      {
        label: "BarCode",
        field: "barCode",
        sort: "asc",
      },
      {
        label: "Kateqory",
        field: "categoryName",
        sort: "asc",
      },
      {
        label: "Qiymeti",
        field: "displayPrice",
        sort: "asc",
      },
    ],
    rows: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/areaManagers/salesPrice?depoNo=${depoNo}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        const rowsData = result.productDisplayPrices.map((item) => ({
          stockName: item.stockName ? item.stockName : "test",
          barCode: item.barCode,
          categoryName: item.categoryName,
          displayPrice: item.displayPrice,
        }));
        setDisplayPrices((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [depoNo]);

  return (
    <div className="content">
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={displayPrices}
        />
      )}
    </div>
  );
};

export default DisplayPrices;
