import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap";

const UpdateProduct = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/Products/getById${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        setProduct(result);
        setCategories(result.categories);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const UpdateProduct = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (product.hasOwnProperty("categories")) delete product["categories"];

    try {
      const jwtToken = Cookies.get("token");

      const response = await fetch(`${apiKey}/nam/Products/edit`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(product),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/products");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    if ((name == "price" || name == "pakageKq") && isNaN(value)) return;
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="content">
      {error && <p className="text-danger">{error}</p>}
      {loading && <p className="p-3 text-warning">...Yüklənir</p>}
      {product && (
        <Form onSubmit={UpdateProduct}>
          <Label htmlFor="stockName">Məhsul Adı*</Label>
          <Input
            required
            value={product.stockName}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="stockName"
            id="stockName"
            placeholder="Məhsul adını daxil et"
          />

          <Label htmlFor="barCode">BarKod*</Label>
          <Input
            required
            value={product.barCode}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="barCode"
            id="barCode"
            placeholder="Barkodu daxil et"
          />

          <Label htmlFor="pakageKq">1 Bağlamadakı Məhsulun Çəkisi(kq)</Label>
          <Input
            value={product.pakageKq}
            onChange={InputChange}
            className="w-50"
            inputMode="decimal"
            type="text"
            name="pakageKq"
            id="pakageKq"
            placeholder="Çəkini(kq) daxil et"
          />

          <Label htmlFor="price">Qiyməti</Label>
          <Input
            inputMode="decimal"
            value={product.price}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="price"
            id="price"
            placeholder="Qiyməti daxil et"
          />

          <Label htmlFor="category">Kateqoriyalar*</Label>
          <select
            required
            value={product.categoryId}
            onChange={InputChange}
            className="form-control w-25"
            name="categoryId"
          >
            <option value="">Kateqoriya seçin</option>
            {categories &&
              categories.map((cat, key) => {
                return (
                  <option key={key} value={cat.id}>
                    {cat.name}
                  </option>
                );
              })}
          </select>
          <Button type="submit" disabled={loading}>
            Yenilə
          </Button>
        </Form>
      )}
    </div>
  );
};

export default UpdateProduct;
