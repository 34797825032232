import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap";

const UpdateShop = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [shop, setShop] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newPass, setNewPass] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/Shops/getShop?id=${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }
        const result = await response.json();
        setShop(result);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const UpdateShop = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (shop.hasOwnProperty("tankNo")) delete shop["tankNo"];
    if (shop.hasOwnProperty("canSendOrder")) delete shop["canSendOrder"];
    if (shop.hasOwnProperty("orders")) delete shop["orders"];

    shop.newPassword = newPass;
    try {
      const jwtToken = Cookies.get("token");

      const response = await fetch(`${apiKey}/nam/shops/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(shop),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/shops");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setShop((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="content">
      {error && <p className="text-danger">{error}</p>}
      {loading && <p className="p-3 text-warning">...Yüklənir</p>}
      {shop && (
        <Form onSubmit={UpdateShop}>
          <Label htmlFor="name">Mağazanın Adı*</Label>
          <Input
            required
            value={shop.name}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="name"
            id="name"
            placeholder="Mağazanın adını daxil et"
          />

          <Label htmlFor="newPass">Yeni Şifrə</Label>
          <Input
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
            className="w-50"
            type="text"
            name="newPass"
            id="newPass"
            placeholder="Yeni şifrə daxil et"
          />

          <Label htmlFor="phoneNumber">Elaqe Nomresi*</Label>
          <Input
            inputMode="tel"
            required
            value={shop.phoneNumber}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Telefon nömrəsini daxil et"
          />

          <Label htmlFor="locationLink">Konum*</Label>
          <Input
            required
            value={shop.locationLink}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="locationLink"
            id="locationLink"
            placeholder="Konumu daxil et"
          />

          <Button type="submit" disabled={loading}>
            Yenilə
          </Button>
        </Form>
      )}
    </div>
  );
};

export default UpdateShop;
