import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const AreaManagerUpdateOrder = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(null);
  const [orderModal, setOrderModal] = useState(false);
  const [value, setValue] = useState(null);
  const [note, setNote] = useState(null);
  const [orderItemId, setOrderItemId] = useState("");
  const [orderItems, setOrderItems] = useState(null);

  const [data, setData] = useState({
    columns: [
      {
        label: "Malin Adi",
        field: "stockName",
        sort: "asc",
      },
      {
        label: "Barcode",
        field: "barCode",
        sort: "asc",
      },
      {
        label: "Sayi",
        field: "value",
        sort: "asc",
      },
      {
        label: "Mal qaligi",
        field: "malQaligi",
        sort: "asc",
      },
      {
        label: "Qeyd",
        field: "note",
        sort: "asc",
      },
      {
        label: "Deyiş",
        field: "change",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [shop, setShop] = useState(null);

  const handleChange = (note, value, id) => {
    setNote(note);
    setValue(value);
    setOrderItemId(id);
    setOrderModal(!orderModal);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/AreaManagers/getAllOrders`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        setShop(result.find((item) => item.id === id));
        setOrderItems(result.find((item) => item.id === id).orderItems);
        const rowsData = result
          .find((item) => item.id === id)
          .orderItems.map((orderItem) => ({
            stockName: orderItem.productStockName,
            barCode: orderItem.productBarcode,
            value: orderItem.value,
            note: orderItem.note,
            malQaligi: orderItem.malGaligi,
            change: (
              <>
                <Button
                  disabled={loading}
                  className="btn btn-info"
                  onClick={() =>
                    handleChange(orderItem.note, orderItem.value, orderItem.id)
                  }
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </Button>
              </>
            ),
          }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const rowsData =
      orderItems &&
      orderItems.map((orderItem) => ({
        stockName: orderItem.productStockName,
        barCode: orderItem.productBarcode,
        value: orderItem.value,
        note: orderItem.note,
        malQaligi: orderItem.malGaligi,
        change: (
          <>
            <Button
              disabled={loading}
              className="btn btn-info"
              onClick={() =>
                handleChange(orderItem.note, orderItem.value, orderItem.id)
              }
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </Button>
          </>
        ),
      }));
    setData((prevData) => ({
      ...prevData,
      rows: rowsData,
    }));
  }, [refresh, orderItems]);

  const handleOrderChange = async ()=>{
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/areaManagers/updateOrder`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          id: id,
          orderItems: orderItems,
        }),
      });
      if (!response.ok) {
        setError(await response.text());
        return;
      }
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="content">
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <>
          <div className="d-flex flex-wrap align-items-center pb-3">
            <h1 className="m-0 mr-4">{shop && shop.shopName}</h1>
            <Button color="secondary" onClick={handleOrderChange}>Yadda Saxla</Button>
          </div>
          <OrderModal
            modal={orderModal}
            setModal={setOrderModal}
            value={value}
            note={note}
            setValue={setValue}
            setNote={setNote}
            orderItemId={orderItemId}
            orderItems={orderItems}
            setRefresh={setRefresh}
            refresh={refresh}
          />
          <MDBDataTable
            data-bs-theme="dark"
            responsiveMd
            striped
            bordered
            hover
            searchLabel="Axtarış"
            paginationLabel={["Əvvəl", "Növbəti"]}
            data={data}
          />
        </>
      )}
    </div>
  );
};

const OrderModal = ({
  modal,
  setModal,
  value,
  note,
  setValue,
  setNote,
  orderItemId,
  orderItems,
  refresh,
  setRefresh,
}) => {
  const handleUpdateOrder = async () => {
    const orderItem = orderItems.find(
      (orderItem) => orderItem.id === orderItemId
    );

    orderItem.value = Number(value);
    orderItem.note = note;

    setModal(!modal);
    setRefresh(!refresh);
  };
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(!modal)}>Sifarisi Deyis</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="value">Sayi</Label>
          <Input
            className="text-dark"
            value={value}
            id="value"
            name="value"
            placeholder="Sayi Daxil Edin"
            type="number"
            onChange={(e) => setValue(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="note">Qeyd</Label>
          <Input
            className="text-dark"
            value={note}
            id="note"
            name="note"
            placeholder="Qeyd Daxil Edin"
            type="text"
            onChange={(e) => setNote(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleUpdateOrder}>
          Deyis
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AreaManagerUpdateOrder;
