import React, { useEffect, useState } from "react";
import { apiKey } from "BaseApi/ApiKey";
import "views/Reports/index.scss";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import './index.scss'
import { Title } from "chart.js";

function ChecklistReport({
}) {
  const { depoNo } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const [checklist, setChecklist] = useState({
    minDate: new Date(year, month, 1),
    maxDate: new Date(),
    data: "",
  });
  const [data, setData] = useState({
    columns: [
      {
        label: "Tarix",
        field: "date",
        sort: "asc",
      },
      {
        label: "Bölgə Rəhbəri",
        field: "areaManager",
        sort: "asc",
      },
      {
        label: "Mağaza Adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Vakansiya",
        field: "vakansiya",
        sort: "asc",
      },
      {
        label: "Toplam",
        field: "total",
        sort: "asc",
      },
      {
        label: "qeyd",
        field: "qeyd",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "detail",
        sort: "asc",
      },
    ],
    rows: [],
  });
  const [dataError, setDataError] = useState();
  const [modal, setModal] = useState(false);

  const downloadExcelChecklist = async () => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/api/Checklist/getChecklist?start=${checklist.minDate.toLocaleDateString(
          "en-En",
          options
        )}&end=${checklist.maxDate.toLocaleDateString(
          "en-En",
          options
        )}&loginId=${checklist.loginId}&depoNo=${
          checklist.depoNo
        }&wantExcel=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          checklist: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        checklist: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        checklist: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleModal = (checkListsHelper)=> {
    setChecklist((prevCheckLists)=> ({
      ...prevCheckLists,
      data : checkListsHelper
    }))

    setModal(true);
  }

  useEffect(()=>{
    const fetchData = async ()=> {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");
        const response = await fetch(
          `${apiKey}/api/Checklist/getChecklist?start=${checklist.minDate.toLocaleDateString(
            "en-En",
            options
          )}&end=${checklist.maxDate.toLocaleDateString(
            "en-En",
            options
          )}&depoNo=${depoNo}&wantExcel=false`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (!response.ok) {
          setDataError(await response.text());
          return;
        }

        const result = await response.json();
        const rowsData = result.map((checkList) => ({
          date: checkList.date.split("T")[0],
          areaManager: checkList.manager.name + " " + checkList.manager.surName,
          shopName: checkList.shopName,
          tankNo: checkList.tankNo,
          vakansiya: checkList.vakansiya,
          qeyd: checkList.qeyd,
          total: checkList.total.toFixed(2),
          detail: (
            <div className="d-flex gap-2 align-items-center">
              <Button
                className="btn btn-warning"
                onClick={() => handleModal(checkList.checkListsHelper)}
              >
                Göstər
              </Button>
            </div>
          ),
        }));

        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    }
    
    fetchData();
  }, [])

  return (
    <div className="content">
      {dataError && <p className="text-danger">{dataError}</p>}
      {error && <p className="text-danger">{error}</p>}
      {loading && <p className="text-danger">...yuklenir</p>}
      <MDBDataTable
        className="mt-3 w-75"
        data-bs-theme="dark"
        striped
        bordered
        hover
        searchLabel="Axtarış"
        paginationLabel={["Əvvəl", "Növbəti"]}
        data={data}
      />
      <ReportModal
        modal={modal}
        setModal={setModal}
        loading={loading}
        setLoading={setLoading}
        data={checklist.data}
      />
    </div>
  );
}

function ReportModal({modal, setModal, data, loading, setLoading}){

  
  const [checkListsHelper, setChecklistsHelper] = useState({
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
      },
      {
        label: "Point",
        field: "point",
        sort: "asc",
      },
      {
        label: "Qeyd",
        field: "qeyd",
        sort: "asc",
      },
      {
        label: "Şəkil",
        field: "imageUrl",
        sort: "asc",
      },
    ],
    rows: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(()=> {
    setLoading(true);

    if(data){
      const rowsData = data.map((checkList) => ({
        title: checkList.title,
        point: checkList.point,
        qeyd: checkList.qeyd ? checkList.qeyd : "-",
        imageUrl: <img src={checkList.imagerUrl} style={{"maxWidth" : "300px"}} />
      }));


      setChecklistsHelper((prevData) => ({
        ...prevData,
        rows: rowsData,
      }));

      setLoading(false);
    }
    
  },[data, isOpen])


  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      className="dark-modal"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        <p className="header-modal">Checklist</p>
      </ModalHeader>
      <ModalBody>         
        <MDBDataTable
            className="mt-3 w-75 text-center"
            data-bs-theme="dark"
            striped
            bordered
            hover
            searchLabel="Axtarış"
            paginationLabel={["Əvvəl", "Növbəti"]}
            data={checkListsHelper}
          />
      </ModalBody>
    </Modal>
  );
}

export default ChecklistReport;

