import { apiKey } from "BaseApi/ApiKey";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Delete from "components/Actions/Delete";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import { Button, CardTitle } from "reactstrap/es";
const Products = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [wait, setWait] = useState(false);
  const [page, setPage] = useState({
    current: 1,
    last: null,
    firstPages: 4,
    lastPages: 4
  })
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");
  const ChangeOneOrderStatus = async (id) => {
    try {
      setWait(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/products/updateStatus${id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (!response.ok) {
        setError(await response.text());
        return;
      }
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setWait(false);
    }
  };
  const pages = Array.from({ length: page.last }, (_, i) => i + 1);

  const displayPages = pages.filter((pagee, index) => {
    if (index < page.firstPages) {
      return true;
    }
    if (index >= page.last - page.lastPages) {
      return true;
    }
    if (pagee === page.current - 1 || pagee === page.current + 1) {
      return true;
    }
    if (pagee === page.current) {
      return true;
    }
    return false;
  });

  const hasEllipsis = (start, end) => {
    const range = Array.from({ length: end - start }, (_, i) => start + i);
    return range.some((page) => !displayPages.includes(page));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/Products/getAll?search=${search}&page=${page.current}&limit=10`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        setData(result.data);
        setPage((prevData) => ({
          ...prevData,
          last: result.pageCount,
        }));
        
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh, page.current, search]);

  return (
    <div className="content">
      <CardTitle className="d-flex justify-content-between align-items-center">
        <Link to="/admin/products/create" className="btn btn-info">
          Məhsul yarat
        </Link>
        <Input
          className="w-25"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search"
          name="search"
          placeholder="Axtarış dəyərini daxil edin!"
        ></Input>
      </CardTitle>
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Mehsulun adi</th>
                <th>Barkod</th>
                <th>Kateqoriya</th>
                <th>Qiymeti</th>
                <th>Diger</th>
              </tr>
            </thead>
            <tbody>
              {data.map((product) => (
                <tr key={product.productId}>
                  <td>{product.stockName}</td>
                  <td>{product.barCode}</td>
                  <td>{product.categoryName}</td>
                  <td>
                    <p className="text-center">
                      {product.price ? product.price + "₼" : "-"}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex gap-2 flex-wrap justify-content-center">
                      <div className="d-flex gap-2 flex-wrap justify-content-center">
                        <Button
                          disabled={wait}
                          onClick={() =>
                            ChangeOneOrderStatus(product.productId)
                          }
                          color={product.isAllowed ? "danger" : "info"}
                        >
                          {product.isAllowed ? "Deaktiv Et" : "Aktiv Et"}
                        </Button>
                        <Delete
                          setRefresh={setRefresh}
                          type="Products"
                          message={`${product.stockName} silinəcək!`}
                          id={product.productId}
                        />
                      </div>
                      <div className="d-flex gap-2 flex-wrap justify-content-center">
                        <Link
                          className="btn btn-success"
                          to={`/admin/products/update/${product.productId}`}
                        >
                          <i className="fa-solid fa-pen-to-square" />
                        </Link>
                        <Link
                          className="btn btn-info"
                          to={`/admin/products/detail/${product.productId}`}
                        >
                          <i className="fa-solid fa-circle-info" />
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.First
              onClick={() =>
                setPage((prevData) => ({
                  ...prevData,
                  current: 1,
                }))
              }
            />
            <Pagination.Prev
              onClick={() =>
                setPage((prevData) => ({
                  ...prevData,
                  current: page.current - 1,
                }))
              }
            />

            {displayPages.map((pagee, index) => (
              <React.Fragment key={pagee}>
                {index > 0 &&
                  hasEllipsis(displayPages[index - 1] + 1, pagee) && (
                    <Pagination.Ellipsis disabled key={`ellipsis-${index}`} />
                  )}
                <Pagination.Item
                  active={pagee === page.current}
                  onClick={() =>
                    setPage((prevData) => ({
                      ...prevData,
                      current: pagee,
                    }))
                  }
                >
                  {pagee}
                </Pagination.Item>
              </React.Fragment>
            ))}
            <Pagination.Next
              onClick={() =>
                setPage((prevData) => ({
                  ...prevData,
                  current: page.current + 1,
                }))
              }
            />
            <Pagination.Last
              onClick={() =>
                setPage((prevData) => ({
                  ...prevData,
                  current: page.last,
                }))
              }
            />
          </Pagination>
        </>
      )}
    </div>
  );
};

export default Products;
