import React, { useEffect, useState } from "react";
import { Checkbox, Label, Textarea } from "flowbite-react";
import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import "../CheckList/index.scss";
import { jwtDecode } from "jwt-decode";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const CheckList = () => {
  const [loading, setLoading] = useState(false);

  const [checkListItem, setCheckListItem] = useState({
    number: 0,
    point: "",
    qeyd: "",
    imagerUrl: "",
  });
  const [depoNo, setDepoNo] = useState("");
  const [note, setNote] = useState("");
  const [vakansiya, setVakansiya] = useState();
  const [success, setSuccess] = useState({
    imageIsExist: null,
    checklistIsUpdate: null,
  });
  const [orderModal, setOrderModal] = useState(false);
  const [error, setError] = useState({
    point: null,
    imagerUrl: null,
    depoNo: null,
    vakansiya : null,
    whole: null
  });
  const [data, setData] = useState([
    {
      number: 0,
      name: "Düzülüş Meyvə",
    },
    {
      number: 1,
      name: "Soyuducu Standartı",
    },
    {
      number: 2,
      name: "Endirimli malların sərgilənməsi",
    },
    {
      number: 3,
      name: "Anbar_Stoku_Meyvə",
    },
    {
      number: 4,
      name: "Imhalıq Mallar/Kq",
    },
    {
      number: 5,
      name: "Teşir No1",
    },
    {
      number: 6,
      name: "Teşir No2",
    },
    {
      number: 7,
      name: "Teşir No3",
    },
    {
      number: 8,
      name: "Düzülüş Çərəz",
    },
    {
      number: 9,
      name: "Anbar Stoku Çərəz",
    },
    {
      number: 10,
      name: "Vitrin Ət",
    },
    {
      number: 11,
      name: "Imha Ət/Kq",
    },
  ]);
  const [checkListItems, setCheckListItems] = useState([
    {
      number: 0,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 1,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 2,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 3,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 4,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 5,
      point: "+",
      qeyd: "",
      imagerUrl: "",
      checked: true,
    },
    {
      number: 6,
      point: "+",
      qeyd: "",
      imagerUrl: "",
      checked: true,
    },
    {
      number: 7,
      point: "+",
      qeyd: "",
      imagerUrl: "",
      checked: true,
    },
    {
      number: 8,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 9,
      point: "",
      qeyd: "",
      imagerUrl: "",
      checked: false,
    },
    {
      number: 10,
      point: "+",
      qeyd: "",
      imagerUrl: "",
      checked: true,
    },
    {
      number: 11,
      point: "+",
      qeyd: "",
      imagerUrl: "",
      checked: true,
    },
  ]);
  const [categories, setCategories] = useState([]);

  const handleChange = () => {
    setOrderModal(!orderModal);
  };

  const handleUploadCheckList = async (e) => {
    if (!depoNo) {
      setError((data) => ({
        ...data,
        depoNo: "DepoNo daxil edin!",
      }));
      return;
    }

    if (!vakansiya) {
      setError((data) => ({
        ...data,
        vakansiya: "Vakansiya daxil edin!",
      }));
      return;
    }

    let isExist = true;

    checkListItems.forEach((item) => {
      if(item.checked == false){
        isExist = false;
      }
    });

    if(!isExist){
      setError((data) => ({
        ...data,
        whole: "Checklist tam doldurulmayib!",
      }));
      return;
    }

    setLoading(true);

    let newArr = [];

    checkListItems.forEach((item)=> {
      let newObject = {
        number: item.number,
        point: item.point,
        qeyd: item.qeyd,
        imagerUrl: item.imagerUrl,
      };

      newArr.push(newObject)
    })

    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/api/CheckList/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tankNo: depoNo,
          qeyd: note,
          vakansiya: vakansiya,
          checkListsHelper: newArr,
        }),
      });

      console.log(await response.text());

      if (!response.ok) {
        const error = await response.text();
        setError(error);
        return;
      }

      setError((data) => ({
        ...data,
        point: null,
        imagerUrl: null,
        depoNo: null,
        vakansiya: null,
        whole: null,
      }));

      setSuccess((prev) => ({
        ...prev,
        checklistIsUpdate: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    for(let category of categories){
      if(category.categoryName === "Tara Satışı"){
        setCheckListItems((data) =>
          data.map((checklist) =>
            checklist.number === 5 ||
            checklist.number === 6 ||
            checklist.number === 7
              ? { ...checklist, checked: false, point: ""}
              : checklist
          )
        );
      }

      if ((category.categoryName === "Ət")) {
        setCheckListItems((data) =>
          data.map((checklist) =>
            checklist.number === 10 || checklist.number === 11
              ? { ...checklist, checked: false, point: ""}
              : checklist
          )
        );
      }
    }
  }, [categories])

  useEffect(() => {
    const fetchData = async () => {
      const encodedToken = jwtDecode(Cookies.get("token"));
      const id =
        encodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        ];

      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/AreaManagers/getById?id=${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        setCategories(result.managerCategories);

      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content row">
      <div className="d-flex flex-column gap-2 flex-wrap mb-4 col-12 col-md-9 col-lg-7">
        <h1>Şöbə Müdiri Yoxlama Siyahısı</h1>
        <div className="text-white">
          <FormGroup>
            <Label htmlFor="depo_No">DepoNo*</Label>         
            <Input
              required
              value={depoNo}
              onChange={(e) => setDepoNo(e.target.value)}
              className="ph-white"
              type="text"
              name="depo_No"
              id="depo_No"
              placeholder="DepoNo daxil edin:"
            />
            {error.depoNo ? (
              <p className="text-danger rounded pt-1 font-weight-normal">
                {error.depoNo}
              </p>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="vakansiya" className="mt-3">
              Vakansiya*
            </Label>
            <Input
              required
              onChange={(e) => setVakansiya(e.target.value)}
              className="ph-white"
              type="number"
              name="vakansiya"
              id="vakansiya"
              placeholder="Vakansiya daxil edin:"
            />
            {error.vakansiya ? (
              <p className="text-danger rounded pt-1 font-weight-normal">
                {error.vakansiya}
              </p>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="totalNote" className="mt-3">
              Umumi Qeyd
            </Label>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="form-control px-3"
              type="text"
              name="totalNote"
              id="totalNote"
              placeholder="Qeyd yaz"
            />
          </FormGroup>
        </div>
        <div className="d-flex flex-wrap gap-4 text-white mt-3">
          {checkListItems.map((item, index) => {
            return (
              <div key={item.number} className="d-flex gap-2 ">
                {index + 1}.{" "}
                <Checkbox
                  checked={(item.checked) ? true : false}
                  disabled={item.checked && "disabled"}
                  onChange={() => {
                    setCheckListItem((data) => ({
                      ...data,
                      number: item.number,
                    }));
                    handleChange();
                    setSuccess((main) => ({
                      ...main,
                      imageIsExist: null,
                    }));
                  }}
                  style={{ width: "18px", height: "18px" }}
                ></Checkbox>
              </div>
            );
          })}
        </div>
        <CheckListModal
          modal={orderModal}
          setModal={setOrderModal}
          checkListItem={checkListItem}
          setCheckListItem={setCheckListItem}
          checkListItems={checkListItems}
          data={data}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
          success={success}
          setSuccess={setSuccess}
        />
        <div className="mt-3">
          <Button
            className="btn btn-success"
            onClick={() => handleUploadCheckList()}
            disabled={loading}
          >
            Yadda Saxla
          </Button>
          {success.checklistIsUpdate && (
            <p style={{ color: "green" }} className=" rounded mt-2">
              Uğurla yuklendi
            </p>
          )}
          {error.whole ? (
            <p className="text-danger rounded mt-2 font-weight-normal">
              {error.whole}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const CheckListModal = ({
  modal,
  setModal,
  checkListItem,
  setCheckListItem,
  checkListItems,
  data,
  loading,
  setLoading,
  success,
  setSuccess,
  error,
  setError
}) => {

  useEffect(()=> {
    setCheckListItem((data) => ({
      ...data,
      point: null,
      imagerUrl: null,
      qeyd: null,
    }));

  }, [modal])

  const handlechange = ()=> {

    let item = checkListItems.find((item) => item.number === checkListItem.number);
    item.imagerUrl = checkListItem.imagerUrl
    item.point = checkListItem.point;
    item.qeyd = checkListItem.qeyd;

    if(!item.imagerUrl){
      setError((data) => ({
        ...data,
        imagerUrl : "Şəkil yerləşdirmək vacibdir!",
      }))

      return
    }

    if(!item.point){
      setError((data) => ({
        ...data,
        point: "Point seçilmelidir!",
      }));

      return
    }

    item.checked = true;

    setError((data) => ({
      ...data,
      point: null,
      imagerUrl: null,
    }))
    setModal(false);

    setCheckListItem((data) => ({
      ...data,
      imagerUrl : null,
      point: null,
      qeyd: null,
    }))

  }

  const InputChange = (e) => {
    const { name, value } = e.target;
    setCheckListItem((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/api/CheckList/getPhotoLink`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      let result =  await response.text();

      setCheckListItem((data) => ({
        ...data,
        imagerUrl: result
      }))

      if (!response.ok) {
        const error = await response.text();
        setError((data) => ({
          ...data,
          imagerUrl: error,
        }));
        return;
      }

      setError((data) => ({
        ...data,
        imagerUrl: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        imageIsExist: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)}>
      <ModalHeader toggle={() => setModal(!modal)}>
        {data.find((item) => item.number === checkListItem.number).name}
      </ModalHeader>
      <ModalBody>
        <FormGroup className="w-75">
          <Label htmlFor="point" className="d-block mb-0">
            Point
          </Label>
          <select
            name="point"
            id="point"
            className="w-25 mt-1"
            onChange={(e) => InputChange(e)}
          >
            <option value="default" disabled selected>
              Seçin
            </option>
            <option value="+">+</option>
            <option value="-">-</option>
          </select>
          <p className="text-danger rounded pt-1 font-weight-normal">
            {error.point ?? error.point}
          </p>
        </FormGroup>
        <div className="w-75 d-flex flex-column">
          <label htmlFor="image" className="mb-1">
            Image
          </label>
          <div className="file-input">
            <input
              type="file"
              name="file-input"
              id="file-input"
              className="file-input__input"
              onChange={(e) => handleImageUpload(e)}
            />
            <label className="file-input__label" htmlFor="file-input">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="upload"
                className="svg-inline--fa fa-upload fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                ></path>
              </svg>
              <span>Upload file</span>
            </label>
            {error.imagerUrl ? (
              <p className="text-danger rounded pt-1 font-weight-normal">{error.imagerUrl}</p>
            ) : null}

            {success.imageIsExist && (
              <p style={{ color: "green" }} className=" rounded pt-1">
                Uğurla yuklendi
              </p>
            )}
          </div>
        </div>
        <FormGroup>
          <Label htmlFor="qeyd" className="d-block mb-2 mt-3">
            Qeyd
          </Label>
          <Textarea
            id="qeyd"
            name="qeyd"
            className="custom-textarea"
            onChange={(e) => InputChange(e)}
          ></Textarea>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => handlechange()} disabled={loading && true}>
          Yadda Saxla
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CheckList;
