import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap";

const UpdateAreaManager = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [areaManager, setAreaManager] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/AreaManagers/getById?id=${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        console.log(result);
        setAreaManager(result);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const UpdateAreaManager = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (areaManager.hasOwnProperty("categories"))
      delete areaManager["categories"];

    try {
      const jwtToken = Cookies.get("token");

      const response = await fetch(`${apiKey}/nam/AreaManagers/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(areaManager),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/areamanagers");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;
    setAreaManager((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="content">
      {error && <p className="text-danger">{error}</p>}
      {loading && <p className="p-3 text-warning">...Yüklənir</p>}
      {areaManager && (
        <Form onSubmit={UpdateAreaManager}>
          <Label htmlFor="name">Adı*</Label>
          <Input
            required
            value={areaManager.name}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="name"
            id="name"
            placeholder="Adını daxil et"
          />

          <Label htmlFor="surName">Soyad*</Label>
          <Input
            required
            value={areaManager.surName}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="surName"
            id="surName"
            placeholder="Soyadini daxil et"
          />

          <Label htmlFor="email">Email</Label>
          <Input
            value={areaManager.email}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="email"
            id="email"
            placeholder="Email daxil et"
          />

          <Label htmlFor="loginId">Login Id*</Label>
          <Input
            required
            value={areaManager.loginId}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="loginId"
            id="loginId"
            placeholder="LoginId daxil et"
          />

          <Label htmlFor="passWord">Hesab Şifrəsi*</Label>
          <Input
            value={areaManager.password}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="password"
            id="password"
            placeholder="Şifrəni daxil et"
          />

          <Label htmlFor="phoneNuber">Elaqe Nomresi*</Label>
          <Input
            inputMode="tel"
            required
            value={areaManager.phoneNuber}
            onChange={InputChange}
            className="w-50"
            type="text"
            name="phoneNuber"
            id="phoneNuber"
            placeholder="Telefon nömrəsini daxil et"
          />
          <Button type="submit" disabled={loading}>
            Yenilə
          </Button>
        </Form>
      )}
    </div>
  );
};

export default UpdateAreaManager;
