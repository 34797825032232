import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CreateOrder from "views/Orders/CreateOrder";
import routes from "routes.js";
import logo from "assets/img/react-logo.png";
import Sidebar from "components/Sidebar/Sidebar";
import ShopNavbar from "components/Navbars/ShopNavbar";
import PerfectScrollbar from "perfect-scrollbar";

var ps;

const ShopLayout = () => {
  const navigate = useNavigate();
  const [shopName, setShopName] = useState(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const mainPanelRef = React.useRef(null);
  const location = useLocation();
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const encodedToken = jwtDecode(token);
      const name =
        encodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        ];
      setShopName(name);
      const role =
        encodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      if (role === "SuperAdmin") navigate("/admin/dashboard");
      else if (role === "AreaManager") navigate("/manager/order");
    }
  });

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/orders") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar
          name={"Shop"}
          routes={routes}
          logo={{
            outterLink: "https://www.creative-tim.com/",
            text: "Creative Tim",
            imgSrc: logo,
          }}
          toggleSidebar={toggleSidebar}
        />
        <div className="main-panel" ref={mainPanelRef} data={"blue"}>
          <ShopNavbar
            brandText={shopName}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
          <Routes>
            {getRoutes(routes)}
            <Route path="/create" element={<CreateOrder />} />
            <Route />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default ShopLayout;
