import React, { Fragment, useEffect, useState } from "react";
import { apiKey } from "BaseApi/ApiKey";
import { Label } from "flowbite-react";
import { Button, FormGroup, Input } from "reactstrap";
import "views/Reports/index.scss";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import {
  Table
} from "reactstrap";

const Reports = () => {
  const [dataExcel, setDataExcel] = useState();
  const [dataShopExcel, setDataShopExcel] = useState();
  const [data, setData] = useState({
    columns: [
      {
        label: "Stok adı",
        field: "stockName",
        sort: "asc",
      },
      {
        label: "BarKod",
        field: "barCode",
        sort: "asc",
      },
      {
        label: "Kq Ədəd",
        field: "kq/Count",
        sort: "asc",
      },
      {
        label: "Satış",
        field: "sale",
        sort: "asc",
      },
      {
        label: "Alıs Deyeri",
        field: "purchaseValue",
        sort: "asc",
      },
      {
        label: "Gəlir",
        field: "income",
        sort: "asc",
      },
      {
        label: "Marja",
        field: "marja",
        sort: "asc",
      },
      {
        label: "İmha Kq",
        field: "destruction_kq",
        sort: "asc",
      },
      {
        label: "İmha Azn",
        field: "destruction_azn",
        sort: "asc",
      },
      {
        label: "İmha Marja",
        field: "destruction_marja",
        sort: "asc",
      },
      {
        label: "İnventar kq",
        field: "inventar_kq",
        sort: "asc",
      },
      {
        label: "İnventar Azn",
        field: "inventar_azn",
        sort: "asc",
      },
      {
        label: "İnventar Marja",
        field: "inventar_marja",
        sort: "asc",
      },
      {
        label: "İmha+İnvent",
        field: "desctruction_invent",
        sort: "asc",
      },
      {
        label: "Umumi Gelir",
        field: "totalIncome",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [dataShop, setDataShop] = useState({
    columns: [
      {
        label: "Depo No",
        field: "depoNo",
        sort: "asc",
      },
      {
        label: "Magaza Adi",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Bolge Mudiri",
        field: "areaManager",
        sort: "asc",
      },
      {
        label: "Satış Miqdari",
        field: "saleAmount",
        sort: "asc",
      },
      {
        label: "Satış AZN",
        field: "sale_Azn",
        sort: "asc",
      },
      {
        label: "Satış Maya",
        field: "sale_Maya",
        sort: "asc",
      },
      {
        label: "Satış Marja",
        field: "sale_Marja",
        sort: "asc",
      },
      {
        label: "İmha Miqdari",
        field: "destructionAmount",
        sort: "asc",
      },
      {
        label: "İmha AZN",
        field: "destruction_Azn",
        sort: "asc",
      },
      {
        label: "İmha Marja",
        field: "destruction_Marja",
        sort: "asc",
      },
      {
        label: "İnventar Miqdar",
        field: "inventarAmount",
        sort: "asc",
      },
      {
        label: "İnventar AZN",
        field: "inventar_Azn",
        sort: "asc",
      },
      {
        label: "İnventar Marja",
        field: "inventar_Marja",
        sort: "asc",
      },
      {
        label: "Gəlir",
        field: "income",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [loading, setLoading] = useState(false);
  const [depoNo, setDepoNo] = useState("");

  const [depoNoPredict, setDepoNoPredict] = useState("");
  const [barCode, setBarCode] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState();
  const [success, setSuccess] = useState({
    totalReport: null,
    checklist: null,
  });
  const [totalAmount, setTotalAmount] = useState({
    "kq/Count": null,
    sale: null,
    purchaseValue: null,
    income: null,
    marja: null,
    destruction_kq: null,
    destruction_azn: null,
    destruction_marja: null,
    inventar_kq: null,
    inventar_azn: null,
    inventar_marja: null,
    desctruction_invent: null,
    totalIncome: null,
  });
  const [totalAmountShop, setTotalAmountShop] = useState({
    saleAmount: null,
    saleAzn: null,
    saleMaya: null,
    saleMarja: null,
    destruction_amount: null,
    destruction_azn: null,
    destruction_marja: null,
    inventar_amount: null,
    inventar_marja: null,
    inventar_azn: null,
    totalIncome: null,
  });

  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });

  const [date, setDate] = useState(new Date());

  const [dataError, setDataError] = useState(null);
  const [error, setError] = useState({
    totalReport: null,
    checklist: null,
  });

  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setDataError(await response.text());
        }

        const result = await response.json();
        setCategories(result);
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUploadTotalReport = async (type) => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/mainHesabat?start=${dateTime.min.toLocaleDateString(
          "en-En",
          options
        )}&end=${dateTime.max.toLocaleDateString(
          "en-En",
          options
        )}&depoNo=${depoNo}&barCode=${barCode}&categoryId=${category}&wantExcel=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const downloadExcelPredictReport = async () => {
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/excels/getPredict?shopId=${depoNoPredict}&date=${date.toLocaleDateString(
          "en-En",
          options
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  }

  const showDatas = async () => {
    setDataExcel(null);
    setDataShopExcel(null);
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/mainHesabat?start=${dateTime.min.toLocaleDateString(
          "en-En",
          options
        )}&end=${dateTime.max.toLocaleDateString(
          "en-En",
          options
        )}&depoNo=${depoNo}&barCode=${barCode}&categoryId=${category}&wantExcel=false`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        setDataError(await response.text());
        return;
      }

      const result = await response.json();

      if (!barCode) {
        setDataExcel(result);
        setTotalAmount({
          "kq/Count": result.reduce((acc, curr) => acc + curr.kq, 0).toFixed(2),
          sale: result.reduce((acc, curr) => acc + curr.satis, 0).toFixed(2),
          purchaseValue: result
            .reduce((acc, curr) => acc + curr.alisDeyeri, 0)
            .toFixed(2),
          income: result.reduce((acc, curr) => acc + curr.gelir, 0).toFixed(2),
          marja: (
            result.reduce((acc, curr) => acc + curr.marja, 0) / result.length
          ).toFixed(2),
          destruction_kq: result
            .reduce((acc, curr) => acc + curr.imhaKq, 0)
            .toFixed(2),
          destruction_azn: result
            .reduce((acc, curr) => acc + curr.imhaAzn, 0)
            .toFixed(2),
          destruction_marja: (
            result.reduce((acc, curr) => acc + curr.imhaMarja, 0) /
            result.length
          ).toFixed(2),
          inventar_kq: result
            .reduce((acc, curr) => acc + curr.sayim, 0)
            .toFixed(2),
          inventar_azn: result
            .reduce((acc, curr) => acc + curr.sayimAzn, 0)
            .toFixed(2),
          inventar_marja: (
            result.reduce((acc, curr) => acc + curr.sayimMarja, 0) /
            result.length
          ).toFixed(2),
          desctruction_invent: (
            result.reduce((acc, curr) => acc + curr.imhaInventar, 0) /
            result.length
          ).toFixed(2),
          totalIncome: result
            .reduce((acc, curr) => acc + curr.total, 0)
            .toFixed(2),
        });
        const rowsData = result.map((product) => ({
          stockName: product.stockName,
          barCode: product.barCode,
          "kq/Count": parseFloat(product.kq.toFixed(2)),
          sale: parseFloat(product.satis.toFixed(2)),
          purchaseValue: parseFloat(product.alisDeyeri.toFixed(2)),
          income: parseFloat(product.gelir.toFixed(2)),
          marja: parseFloat(product.marja.toFixed(2)),
          destruction_kq: parseFloat(product.imhaKq.toFixed(2)),
          destruction_azn: parseFloat(product.imhaAzn.toFixed(2)),
          destruction_marja: parseFloat(product.imhaMarja.toFixed(2)),
          inventar_kq: parseFloat(product.sayim.toFixed(2)),
          inventar_azn: parseFloat(product.sayimAzn.toFixed(2)),
          inventar_marja: parseFloat(product.sayimMarja.toFixed(2)),
          desctruction_invent:
            parseFloat(product.imhaInventar.toFixed(2)),
          totalIncome:
            parseFloat(product.total.toFixed(2)) > 0 ? (
              <p className="text-success">
                {parseFloat(product.total.toFixed(2))}
              </p>
            ) : (
              <p className="text-danger">
                {parseFloat(product.total.toFixed(2))}
              </p>
            ),
        }));

        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } else {
        setDataShopExcel(result);
        setTotalAmountShop({
          saleAmount: result
            .reduce((acc, curr) => acc + curr.satisMiqdari, 0)
            .toFixed(2),
          saleAzn: result
            .reduce((acc, curr) => acc + curr.satisAzn, 0)
            .toFixed(2),
          saleMaya: result
            .reduce((acc, curr) => acc + curr.satisMaya, 0)
            .toFixed(2),
          saleMarja: (
            result.reduce((acc, curr) => acc + curr.satisMarja, 0) /
            result.length
          ).toFixed(2),
          destruction_amount: result
            .reduce((acc, curr) => acc + curr.imhaMiqdar, 0)
            .toFixed(2),
          destruction_azn: result
            .reduce((acc, curr) => acc + curr.imhaAzn, 0)
            .toFixed(2),
          destruction_marja: (
            result.reduce((acc, curr) => acc + curr.imhaMarja, 0) /
            result.length
          ).toFixed(2),
          inventar_azn: result
            .reduce((acc, curr) => acc + curr.sayimAzn, 0)
            .toFixed(2),
          inventar_marja: (
            result.reduce((acc, curr) => acc + curr.sayimMarja, 0) / result.length
          ).toFixed(2),
          inventar_amount: result
            .reduce((acc, curr) => acc + curr.sayimMiqdar, 0)
            .toFixed(2),
          totalIncome: result
            .reduce((acc, curr) => acc + curr.gelir, 0)
            .toFixed(2),
        });
        const rowsData = result.map((shop) => ({
          depoNo: shop.depoNo,
          shopName: shop.shopName,
          areaManager: shop.areaManager,
          saleAmount: parseFloat(shop.satisMiqdari.toFixed(2)),
          sale_Azn: parseFloat(shop.satisAzn.toFixed(2)),
          sale_Maya: parseFloat(shop.satisMaya.toFixed(2)),
          sale_Marja: parseFloat(shop.satisMarja.toFixed(2)),
          destructionAmount: shop.imhaMiqdar,
          destruction_Azn: parseFloat(shop.imhaAzn.toFixed(1)),
          destruction_Marja: parseFloat(shop.imhaMarja.toFixed(2)),
          inventarAmount: parseFloat(shop.sayimMiqdar.toFixed(2)),
          inventar_Azn: parseFloat(shop.sayimAzn.toFixed(2)),
          inventar_Marja: parseFloat(shop.sayimMarja.toFixed(2)),
          income:
            parseFloat(shop.gelir.toFixed(2)) > 0 ? (
              <p className="text-success">
                {parseFloat(shop.gelir.toFixed(2))}
              </p>
            ) : (
              <p className="text-danger">{parseFloat(shop.gelir.toFixed(2))}</p>
            ),
        }));

        setDataShop((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      }
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-4">
        <div className="bg-info text-white py-4 px-4 rounded position-relative">
          <div className="mb-2 block">
            <Label
              htmlFor="ihes"
              style={{ fontSize: "1.8em" }}
              value="Rahat Market Umumi Hesabat"
              className="text-lg"
            />
          </div>
          <FormGroup>
            <Label htmlFor="min">Tarixdən</Label>
            <Input
              className="text-dark"
              value={dateTime.min.toISOString().split("T")[0]}
              id="min"
              name="min"
              placeholder="Tarixdən"
              type="date"
              onChange={InputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="max">Tarixə</Label>
            <Input
              onChange={InputChange}
              className="text-dark"
              value={dateTime.max.toISOString().split("T")[0]}
              id="max"
              name="max"
              placeholder="Tarixdən"
              type="date"
            />
          </FormGroup>
          {!barCode && (
            <FormGroup>
              <Label htmlFor="depoNo">DepoNo</Label>
              <Input
                className="text-dark customInp"
                id="depoNo"
                name="depoNo"
                placeholder="DepoNo Daxil edin"
                type="number"
                onChange={(e) => setDepoNo(e.target.value)}
              />
            </FormGroup>
          )}
          {!depoNo && (
            <FormGroup>
              <Label htmlFor="barCode">BarCode</Label>
              <Input
                className="text-dark"
                id="barCode"
                name="barCode"
                placeholder="BarCode Daxil edin!"
                type="number"
                onChange={(e) => setBarCode(e.target.value)}
              />
            </FormGroup>
          )}
          {!barCode && (
            <FormGroup>
              <Label htmlFor="categoryId">Kateqoriyalar</Label>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="form-control text-black"
                name="categoryId"
              >
                <option value="">Bütün Kateqoriyalar</option>
                {categories &&
                  categories.map((cat, key) => {
                    return (
                      <option key={key} value={cat.categoryId}>
                        {cat.categoryName}
                      </option>
                    );
                  })}
              </select>
            </FormGroup>
          )}
          <div className="d-flex justify-content-center gap-2">
            <Button
              disabled={loading}
              className="w-50"
              color="warning"
              onClick={() => {
                showDatas("totalReport");
              }}
            >
              Goster
            </Button>
            <Button
              disabled={loading}
              className="w-50"
              color="default"
              onClick={() => {
                handleUploadTotalReport("totalReport");
              }}
            >
              Yukle
            </Button>
          </div>
          {error.totalReport && (
            <p className="bg-danger rounded p-1">Xəta: {error.totalReport}</p>
          )}
          {success.totalReport && (
            <p className="bg-success rounded text-dark p-1 mt-2">
              Uğurla Yükləndi
            </p>
          )}
        </div>
        <div className="bg-info text-white py-4 px-4 rounded position-relative h-50">
          <div className="mb-2 block">
            <Label
              htmlFor="ihes"
              style={{ fontSize: "1.8em" }}
              value="Sifariş analizi Hesabati"
              className="text-lg"
            />
          </div>
          <FormGroup>
            <Label htmlFor="date">Tarix</Label>
            <Input
              className="text-dark"
              value={date.toISOString().split("T")[0]}
              id="date"
              name="date"
              placeholder="Tarixi Daxil edin"
              type="date"
              onChange={(e) => setDate(new Date(e.target.value))}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="depoNo">DepoNo</Label>
            <Input
              className="text-dark customInp"
              id="depoNo"
              name="depoNo"
              placeholder="DepoNo Daxil edin"
              type="number"
              onChange={(e) => setDepoNoPredict(e.target.value)}
            />
          </FormGroup>
          <div className="d-flex justify-content-center gap-2">
            <Button
              disabled={loading}
              className="w-50"
              color="default"
              onClick={() => {
                downloadExcelPredictReport();
              }}
            >
              Yukle
            </Button>
          </div>
        </div>
        <ChecklistReport
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
          options={options}
        />
      </div>
      {dataError && <p className="text-danger">{dataError}</p>}
      {loading && <p>...Yüklənir</p>}
      {dataExcel && (
        <Fragment>
          <Table dark responsive className="mt-5">
            <thead className="text-primary">
              <tr>
                <th scope="col">Toplam</th>
                <th scope="col">Kq ƏdƏd</th>
                <th scope="col">Satiş</th>
                <th scope="col">Alis Dəyəri</th>
                <th scope="col">Gəlir</th>
                <th scope="col">Marja</th>
                <th scope="col">Imha Kq</th>
                <th scope="col">Imha Azn</th>
                <th scope="col">Imha Marja</th>
                <th scope="col">Inventar Kq</th>
                <th scope="col">Inventar Azn</th>
                <th scope="col">Inventar Marja</th>
                <th scope="col">Imha-Imventar</th>
                <th scope="col">Umumi Gəlir</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "15%" }}> - </td>
                <td>{totalAmount["kq/Count"]}</td>
                <td>{totalAmount.sale}</td>
                <td>{totalAmount.purchaseValue}</td>
                <td>{totalAmount.income}</td>
                <td>{totalAmount.marja}%</td>
                <td>{totalAmount.destruction_kq}</td>
                <td>{totalAmount.destruction_azn}</td>
                <td>{totalAmount.destruction_marja}%</td>
                <td>{totalAmount.inventar_kq}</td>
                <td>{totalAmount.inventar_azn}</td>
                <td>{totalAmount.inventar_marja}%</td>
                <td>{totalAmount.desctruction_invent}</td>
                <td>
                  {totalAmount.totalIncome > 0 ? (
                    <p className="text-success">{totalAmount.totalIncome}</p>
                  ) : (
                    <p className="text-danger">{totalAmount.totalIncome}</p>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <MDBDataTable
            className="mt-5"
            data-bs-theme="dark"
            responsiveMd
            striped
            bordered
            hover
            searchLabel="Axtarış"
            paginationLabel={["Əvvəl", "Növbəti"]}
            data={data}
          />
        </Fragment>
      )}
      {dataShopExcel && (
        <Fragment>
          <Table dark responsive className="mt-5">
            <thead className="text-primary">
              <tr>
                <th scope="col">Satis Miqdari</th>
                <th scope="col">Satis Azn</th>
                <th scope="col">Satis Maya</th>
                <th scope="col">Satis Marja</th>
                <th scope="col">Imha Miqdari</th>
                <th scope="col">Imha Azn</th>
                <th scope="col">Imha Marja</th>
                <th scope="col">Inventar Miqdar</th>
                <th scope="col">Inventar AZN</th>
                <th scope="col">Inventar Marja</th>
                <th scope="col">Gelir</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{totalAmountShop.saleAmount}</td>
                <td>{totalAmountShop.saleAzn}</td>
                <td>{totalAmountShop.saleMaya}</td>
                <td>{totalAmountShop.saleMarja}%</td>
                <td>{totalAmountShop.destruction_amount}</td>
                <td>{totalAmountShop.destruction_azn}</td>
                <td>{totalAmountShop.destruction_marja}%</td>
                <td>{totalAmountShop.inventar_amount}</td>
                <td>{totalAmountShop.inventar_azn}</td>
                <td>{totalAmountShop.inventar_marja}%</td>
                <td>
                  {totalAmountShop.totalIncome > 0 ? (
                    <p className="text-success">
                      {totalAmountShop.totalIncome}
                    </p>
                  ) : (
                    <p className="text-danger">{totalAmountShop.totalIncome}</p>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <MDBDataTable
            className="overflow-auto"
            data-bs-theme="dark"
            striped
            bordered
            hover
            searchLabel="Axtarış"
            paginationLabel={["Əvvəl", "Növbəti"]}
            data={dataShop}
          />
        </Fragment>
      )}
    </div>
  );
};

function ChecklistReport({ loading, setLoading, success, setSuccess, error, setError, options }) {

  const [checklist, setChecklist] = useState({
    minDate: new Date(),
    maxDate: new Date(),
    depoNo: null,
    loginId: "",
    data: null,
    isExist: false,
  });
  const [data, setData] = useState({
    columns: [
      {
        label: "Mağaza Adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "DepoNo",
        field: "tankNo",
        sort: "asc",
      },
      {
        label: "Vakansiya",
        field: "vakansiya",
        sort: "asc",
      },
      {
        label: "qeyd",
        field: "qeyd",
        sort: "asc",
      },
      {
        label: "Tarix",
        field: "date",
        sort: "asc",
      },
      {
        label: "Ümümi",
        field: "total",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "detail",
        sort: "asc",
      }
    ],
    rows: [],
  });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setChecklist((data) => ({
      ...data,
      [name]: new Date(value),
    }));
  };

  const downloadExcelChecklist = async () => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/api/Checklist/getChecklist?start=${checklist.minDate.toLocaleDateString(
          "en-En",
          options
        )}&end=${checklist.maxDate.toLocaleDateString(
          "en-En",
          options
        )}&loginId=${checklist.loginId}&depoNo=${checklist.depoNo}&wantExcel=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);

      if (!response.ok) {
        const error = await response.text();
        setError((prev) => ({
          ...prev,
          checklist: error,
        }));
        return;
      }

      setError((prev) => ({
        ...prev,
        checklist: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        checklist: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-info text-white py-4 px-4 rounded position-relative h-50">
        <div className="mb-2 block">
          <Label
            htmlFor="ihes"
            style={{ fontSize: "1.8em" }}
            value="Şöbə müdiri checklist raporu"
            className="text-lg"
          />
        </div>
        <FormGroup>
          <Label htmlFor="minDate">Tarixdən</Label>
          <Input
            className="text-dark"
            value={
              checklist.minDate && checklist.minDate.toISOString().split("T")[0]
            }
            id="minDate"
            name="minDate"
            placeholder="Tarixdən"
            type="date"
            onChange={handleDateChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="maxDate">Tarixə</Label>
          <Input
            className="text-dark"
            value={
              checklist.maxDate && checklist.maxDate.toISOString().split("T")[0]
            }
            id="maxDate"
            name="maxDate"
            placeholder="Tarixdən"
            type="date"
            onChange={handleDateChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="depoNo">DepoNo</Label>
          <Input
            className="text-dark customInp"
            id="depoNo"
            name="depoNo"
            placeholder="DepoNo Daxil edin"
            type="number"
            onChange={(e) =>
              setChecklist((data) => ({
                ...data,
                depoNo: e.target.value,
              }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="loginId">LoginId</Label>
          <Input
            value={checklist.loginId}
            className="text-dark customInp"
            id="loginId"
            name="loginId"
            placeholder="LoginId Daxil edin"
            type="text"
            onChange={(e) =>
              setChecklist((data) => ({
                ...data,
                loginId: e.target.value,
              }))
            }
          />
        </FormGroup>
        <div className="d-flex justify-content-center gap-1">
          <Button
            disabled={loading}
            className="w-50"
            color="default"
            onClick={() => {
              downloadExcelChecklist();
            }}
          >
            Yukle
          </Button>
        </div>
        {error.checklist && (
          <p className="bg-danger rounded p-1">Xəta: {error.checklist}</p>
        )}
        {success.checklist && (
          <p className="bg-success rounded text-dark p-1 mt-2">
            Uğurla Yükləndi
          </p>
        )}
      </div>
      {checklist.isExist ? (
        <MDBDataTable
          className="mt-3 w-75"
          data-bs-theme="dark"
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      ) : undefined}
    </>
  );
}

export default Reports;
