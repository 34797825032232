import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import CreateCategory from "views/Categories/CreateCategory";
import CreateProduct from "views/Products/CreateProduct";
import ShopOrders from "views/Shops/ShopOrders";
import CreateShop from "views/Shops/CreateShop";
import UpdateCategory from "views/Categories/UpdateCategory";
import UpdateProduct from "views/Products/UpdateProduct";
import UpdateShop from "views/Shops/UpdateShop";
import ProductDetail from "views/Products/ProductDetail";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Reports from "views/Reports";
import CreateAreaManager from "views/AreaManagers/CreateAreaManager";
import AreaManagerAddSAndC from "views/AreaManagers/AreaManagerAddSAndC";
import UpdateAreaManager from "views/AreaManagers/UpdateAreaManager";
import AreaManagerDetail from "views/AreaManagers/AreaManagerDetail";
import AreaManagerDeleteShop from "views/AreaManagers/AreaManagerDeleteShop";
import AreaManagerDeleteCategory from "views/AreaManagers/AreaManagerDeleteCategory";
import CheckListTable from "views/CheckLists/CheckListReport";

var ps;

function Admin(props) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const role =
        jwtDecode(token)[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      if (role == "Shop") navigate("/orders/orderlist");
      else if (role == "AreaManager") navigate("/manager/order");
    }
  });
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <React.Fragment>
      <div className="wrapper">
        <Sidebar
          name={"Admin"}
          routes={routes}
          logo={{
            outterLink: "https://www.creative-tim.com/",
            text: "Creative Tim",
            imgSrc: logo,
          }}
          toggleSidebar={toggleSidebar}
        />
        <div className="main-panel" ref={mainPanelRef} data={"blue"}>
          <AdminNavbar
            brandText={getBrandText(location.pathname)}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
          <Routes>
            {getRoutes(routes)}
            <Route
              path="/categories/create"
              element={<CreateCategory />}
              exact
            />
            <Route
              path="/categories/update/:id"
              element={<UpdateCategory />}
              exact
            />
            <Route path="/products/create" element={<CreateProduct />} exact />
            <Route
              path="/products/detail/:id"
              element={<ProductDetail />}
              exact
            />
            <Route
              path="/products/update/:id"
              element={<UpdateProduct />}
              exact
            />
            <Route path="/shops/update/:id" element={<UpdateShop />} exact />
            <Route path="/shops/orders/:id" element={<ShopOrders />} exact />
            <Route path="/shops/create" element={<CreateShop />} exact />
            <Route path="/shops/create" element={<CreateShop />} exact />
            <Route path="/shops/create" element={<CreateShop />} exact />
            <Route path="/reports" element={<Reports />} exact />
            <Route
              path="/checklists/checklistReport/:depoNo"
              element={<CheckListTable />}
              exact
            />

            <Route
              path="/areamanagers/create"
              element={<CreateAreaManager />}
              exact
            />
            <Route
              path="/areamanagers/updateSC/:id"
              element={<AreaManagerAddSAndC />}
              exact
            />
            <Route
              path="/areamanagers/deleteShop"
              element={<AreaManagerDeleteShop />}
              exact
            />
            <Route
              path="/areamanagers/deleteCategory"
              element={<AreaManagerDeleteCategory />}
              exact
            />
            <Route
              path="/areamanagers/update/:id"
              element={<UpdateAreaManager />}
              exact
            />
            <Route
              path="/areamanagers/detail/:id"
              element={<AreaManagerDetail />}
              exact
            />
          </Routes>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Admin;
