import { apiKey } from "BaseApi/ApiKey";
import Delete from "components/Actions/Delete";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap/es";

const ShopList = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [allCancel, setAllCancel] = useState(true);
  const [wait, setWait] = useState(false);

  const [data, setData] = useState({
    columns: [
      {
        label: "Mağazanın adı",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "Konteyner kodu",
        field: "containerCode",
        sort: "asc",
      },
      {
        label: "Telefon nömrəsi",
        field: "phoneNumber",
        sort: "asc",
      },
      {
        label: "Digər",
        field: "other",
        sort: "asc",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/shops/all`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        setAllCancel(!result.every((item) => item.canSendOrder === false));

        const rowsData = result.map((shop) => ({
          shopName: shop.name,
          containerCode: shop.tankNo,
          phoneNumber: shop.phoneNumber,
          other: (
            <div className="d-flex gap-2 flex-wrap justify-content-center">
              <div className="d-flex gap-2 flex-wrap justify-content-center">
                <Link
                  className="btn btn-warning"
                  to={`/admin/shops/orders/${shop.id}`}
                >
                  Sifarişlər
                </Link>
                <Link
                  target="_blank"
                  className="btn btn-info"
                  to={shop.locationLink}
                >
                  Konum <i className="fa-solid fa-location-dot" />
                </Link>
              </div>
              <div className="d-flex gap-2 flex-wrap justify-content-center">
                <Button
                  disabled={wait}
                  onClick={() =>
                    ChangeOneOrderStatus(shop.id, shop.canSendOrder)
                  }
                  color={shop.canSendOrder ? "success" : ""}
                >
                  Sifarişləri {shop.canSendOrder ? "Bağla" : "Aç "}
                </Button>
                <Delete
                  type="Shops"
                  id={shop.id}
                  setRefresh={setRefresh}
                  message={`${shop.name} və ${shop.name} bütün sifarişləri silinəcək silinəcək!`}
                />
                <Link
                  className="btn btn-success"
                  to={`/admin/shops/update/${shop.id}`}
                >
                  <i className="fa-solid fa-pen-to-square" />
                </Link>
              </div>
            </div>
          ),
        }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh, wait]);

  const ChangeAllOrderStatus = async () => {
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/shops/updateAllStatus?canICreate=${!allCancel}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (!response.ok) {
        setError(await response.text());
        return;
      }
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const ChangeOneOrderStatus = async (id, status) => {
    try {
      setWait(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/shops/updateOneStatus?canICreate=${!status}&id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (!response.ok) {
        setError(await response.text());
        return;
      }
      setRefresh(!refresh);
    } catch (error) {
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setWait(false);
    }
  };
  return (
    <div className="content">
      <CardTitle tag="h4">
        <Link to="/admin/shops/create" className="btn btn-warning">
          Mağaza yarat
        </Link>
        <ShopModal modal={modal} setModal={setModal} />
        <Button
          disabled={loading}
          onClick={ChangeAllOrderStatus}
          color={allCancel ? "success" : ""}
        >
          Bütün Sifarişləri {allCancel ? "Bağla" : "Aç "}
        </Button>
      </CardTitle>
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      )}
    </div>
  );
};

const ShopModal = ({ modal, setModal }) => {
  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });

  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  const downloadExcel = () => {
    const downloadLink = `${apiKey}/nam/Orders/iasf?start=${dateTime.min.toLocaleDateString(
      "en-US",
      options
    )}&end=${dateTime.max.toLocaleDateString("en-US", options)}`;
    window.location.href = downloadLink;
    setModal(!modal);
  };
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(!modal)}>Fakturanı yüklə</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="min">Tarixdən</Label>
          <Input
            className="text-dark"
            value={dateTime.min.toISOString().split("T")[0]}
            id="min"
            name="min"
            placeholder="Tarixdən"
            type="date"
            onChange={InputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="max">Tarixə</Label>
          <Input
            onChange={InputChange}
            className="text-dark"
            value={dateTime.max.toISOString().split("T")[0]}
            id="max"
            name="max"
            placeholder="Tarixdən"
            type="date"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-info" onClick={downloadExcel}>
          Yüklə
        </button>{" "}
        <Button color="secondary" onClick={() => setModal(false)}>
          İmtina et
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShopList;
