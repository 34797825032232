import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";

import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName) ? true : false;
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const { routes, rtlActive, name } = props;

  const adminRoutes = routes
    .filter((prop) => prop.layout === "/admin")
    .map((prop, key) => (
      <li
        className={"nav-item" + (activeRoute(prop.path) ? " bg-dark" : "")}
        key={key}
      >
        <NavLink
          to={"/admin" + prop.path}
          className="nav-link"
          activeclassname=""
          onClick={props.toggleSidebar}
        >
          <i className={prop.icon} />
          <p>{rtlActive ? prop.rtlName : prop.name}</p>
        </NavLink>
      </li>
    ));

  const areaManagerRoutes = routes
    .filter((prop) => prop.layout === "/manager")
    .map((prop, key) => (
      <li
        className={"nav-item" + (activeRoute(prop.path) ? " bg-dark" : "")}
        key={key}
      >
        <NavLink
          to={"/manager" + prop.path}
          className="nav-link"
          activeclassname=""
          onClick={props.toggleSidebar}
        >
          <i className={prop.icon} />
          <p>{rtlActive ? prop.rtlName : prop.name}</p>
        </NavLink>
      </li>
    ));

  const shopRoutes = routes
    .filter((prop) => prop.layout === "/orders")
    .map((prop, key) => (
      <li
        className={"nav-item" + (activeRoute(prop.path) ? " bg-dark" : "")}
        key={key}
      >
        <NavLink
          to={"/orders" + prop.path}
          className="nav-link"
          activeclassname=""
          onClick={props.toggleSidebar}
        >
          <i className={prop.icon} />
          <p>{rtlActive ? prop.rtlName : prop.name}</p>
        </NavLink>
      </li>
    ));

  return (
    <div className="sidebar" data={"blue"}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        <div className="logo text-center">
          <Link
            to="/"
            className="simple-text logo-normal bg-dark rounded"
            onClick={props.toggleSidebar}
          >
            AzFresh Admin Panel
          </Link>
        </div>
        <Nav>
          {name === "Admin"
            ? adminRoutes
            : name === "Shop"
            ? shopRoutes
            : areaManagerRoutes}
        </Nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
