import React from "react";
import ReactDOM from "react-dom/client";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App/>
);
