import React, { Fragment, useEffect, useState } from "react";
import { apiKey } from "BaseApi/ApiKey";
import { Label } from "flowbite-react";
import { Button, FormGroup, Input } from "reactstrap";
import Cookies from "js-cookie";
import { MDBDataTable } from "mdbreact";
import { Table } from "reactstrap";
import { jwtDecode } from "jwt-decode";


const Report = () => {
  const [dataExcel, setDataExcel] = useState();
  const [data, setData] = useState({
    columns: [
      {
        label: "Stok adı",
        field: "stockName",
        sort: "asc",
      },
      {
        label: "BarKod",
        field: "barCode",
        sort: "asc",
      },
      {
        label: "Kq Ədəd",
        field: "kq/Count",
        sort: "asc",
      },
      {
        label: "Satış",
        field: "sale",
        sort: "asc",
      },
      {
        label: "Marja",
        field: "marja",
        sort: "asc",
      },
      {
        label: "İmha Kq",
        field: "destruction_kq",
        sort: "asc",
      },
      {
        label: "İmha Azn",
        field: "destruction_azn",
        sort: "asc",
      },
      {
        label: "İmha Marja",
        field: "destruction_marja",
        sort: "asc",
      },
      {
        label: "İnventar kq",
        field: "inventar_kq",
        sort: "asc",
      },
      {
        label: "İnventar Azn",
        field: "inventar_azn",
        sort: "asc",
      },
      {
        label: "İnventar Marja",
        field: "inventar_marja",
        sort: "asc",
      },
      {
        label: "İmha+İnvent",
        field: "desctruction_invent",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [loading, setLoading] = useState(false);
  const [depoNo, setDepoNo] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState();
  const [totalAmount, setTotalAmount] = useState({
    shop: null,
    "kq/Count": null,
    sale: null,
    purchaseValue: null,
    marja: null,
    destruction_kq: null,
    destruction_azn: null,
    destruction_marja: null,
    inventar_kq: null,
    inventar_azn: null,
    inventar_marja: null,
    desctruction_invent: null,
  });
  const [shop, setShops] = useState(null);
  const [role, setRole] = useState("");

  const [dateTime, setDateTime] = useState({
    min: new Date(),
    max: new Date(),
  });

  const [dataError, setDataError] = useState(null);
  const [error, setError] = useState({
    totalReport: null,
  });

  const InputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: new Date(value),
    }));
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setDataError(await response.text());
        }

        const result = await response.json();
        setCategories(result);
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
    const token = Cookies.get("token");
    const encodedToken = jwtDecode(token);
    setRole(
      encodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ])
    
  },[])

  const showDatas = async () => {
    setDataExcel(null);
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/data/mainHesabat?start=${dateTime.min.toLocaleDateString(
          "en-En",
          options
        )}&end=${dateTime.max.toLocaleDateString(
          "en-En",
          options
        )}&depoNo=${depoNo}&categoryId=${category}&wantExcel=false`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        setDataError(await response.text());
        return;
      }

      const result = await response.json();

      setDataExcel(result);
      setTotalAmount({
        "kq/Count": result.reduce((acc, curr) => acc + curr.kq, 0).toFixed(2),
        sale: result.reduce((acc, curr) => acc + curr.satis, 0).toFixed(2),
        marja: (
          result.reduce((acc, curr) => acc + curr.marja, 0) / result.length
        ).toFixed(2),
        destruction_kq: result
          .reduce((acc, curr) => acc + curr.imhaKq, 0)
          .toFixed(2),
        destruction_azn: result
          .reduce((acc, curr) => acc + curr.imhaAzn, 0)
          .toFixed(2),
        destruction_marja: (
          result.reduce((acc, curr) => acc + curr.imhaMarja, 0) / result.length
        ).toFixed(2),
        inventar_azn: result
          .reduce((acc, curr) => acc + curr.sayimAzn, 0)
          .toFixed(2),
        inventar_kq: result
          .reduce((acc, curr) => acc + curr.sayim, 0)
          .toFixed(2),
        inventar_marja: (
          result.reduce((acc, curr) => acc + curr.sayimMarja, 0) / result.length
        ).toFixed(2),
        desctruction_invent: (
          result.reduce((acc, curr) => acc + curr.imhaInventar, 0) /
          result.length
        ).toFixed(2),
      });
      const rowsData = result.map((product) => ({
        stockName: product.stockName,
        barCode: product.barCode,
        "kq/Count": parseFloat(product.kq.toFixed(2)),
        sale: parseFloat(product.satis.toFixed(2)),
        marja: parseFloat(product.marja.toFixed(2)),
        destruction_kq: parseFloat(product.imhaKq.toFixed(2)),
        destruction_azn: parseFloat(product.imhaAzn.toFixed(2)),
        destruction_marja: parseFloat(product.imhaMarja.toFixed(2)),
        inventar_kq: parseFloat(product.sayim.toFixed(2)),
        inventar_azn: parseFloat(product.sayimAzn.toFixed(2)),
        inventar_marja: parseFloat(product.sayimMarja.toFixed(2)),
        desctruction_invent: parseFloat(product.imhaInventar.toFixed(2)),
      }));

      setData((prevData) => ({
        ...prevData,
        rows: rowsData,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/shops/all`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        setShops(result);
        
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content">
      <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-4">
        <div className="bg-info text-white py-4 px-4 rounded position-relative">
          <div className="mb-2 block">
            <Label
              htmlFor="ihes"
              style={{ fontSize: "1.8em" }}
              value="Rahat Market Umumi Hesabat"
              className="text-lg"
            />
          </div>
          <FormGroup>
            <Label htmlFor="min">Tarixdən</Label>
            <Input
              className="text-dark"
              value={dateTime.min.toISOString().split("T")[0]}
              id="min"
              name="min"
              placeholder="Tarixdən"
              type="date"
              onChange={InputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="max">Tarixə</Label>
            <Input
              onChange={InputChange}
              className="text-dark"
              value={dateTime.max.toISOString().split("T")[0]}
              id="max"
              name="max"
              placeholder="Tarixdən"
              type="date"
            />
          </FormGroup>
          {role === "AreaManager" && (
            <FormGroup>
              <Label htmlFor="depoNo">DepoNo</Label>
              <Input
                className="text-dark customInp"
                id="depoNo"
                name="depoNo"
                placeholder="DepoNo Daxil edin"
                type="number"
                onChange={(e) => setDepoNo(e.target.value)}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label htmlFor="categoryId">Kateqoriyalar</Label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="form-control text-black"
              name="categoryId"
            >
              <option value="">Bütün Kateqoriyalar</option>
              {categories &&
                categories.map((cat, key) => {
                  return (
                    <option key={key} value={cat.categoryId}>
                      {cat.categoryName}
                    </option>
                  );
                })}
            </select>
          </FormGroup>
          <div className="d-flex justify-content-center gap-2">
            <Button
              disabled={loading}
              className="w-50"
              color="warning"
              onClick={() => {
                showDatas("totalReport");
              }}
            >
              Goster
            </Button>
          </div>
          {error.totalReport && (
            <p className="bg-danger rounded p-1">Xəta: {error.totalReport}</p>
          )}
        </div>
      </div>

      {dataError && <p className="text-danger">{dataError}</p>}
      {loading && <p>...Yüklənir</p>}
      {dataExcel && (
        <Fragment>
          <Table dark responsive className="mt-5 overflow-auto">
            <thead className="text-primary">
              <tr>
                <th scope="col">Kq_Eded</th>
                <th scope="col">Satis</th>
                <th scope="col">Marja</th>
                <th scope="col">Imha Kq</th>
                <th scope="col">Imha Azn</th>
                <th scope="col">Imha Marja</th>
                <th scope="col">Inventar Kq</th>
                <th scope="col">Inventar Azn</th>
                <th scope="col">Inventar Marja</th>
                <th scope="col">Imha-Imventar</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{totalAmount["kq/Count"]}</td>
                <td>{totalAmount.sale}</td>
                <td>{totalAmount.marja}%</td>
                <td>{totalAmount.destruction_kq}</td>
                <td>{totalAmount.destruction_azn}</td>
                <td>{totalAmount.destruction_marja}</td>
                <td>{totalAmount.inventar_kq}</td>
                <td>{totalAmount.inventar_azn}</td>
                <td>{totalAmount.inventar_marja}%</td>
                <td>{totalAmount.desctruction_invent}</td>
              </tr>
            </tbody>
          </Table>
          <MDBDataTable
            data-bs-theme="dark"
            responsiveMd
            striped
            bordered
            hover
            searchLabel="Axtarış"
            paginationLabel={["Əvvəl", "Növbəti"]}
            data={data}
          />
        </Fragment>
      )}
    </div>
  );
};

export default Report;
