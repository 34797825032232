import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const AreaManagerDeleteCategory = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [success, setSuccess] = useState(false);
  const [category, setCategory] = useState("");
  console.log(category);
  const [categories, setCategories] = useState();
  const [dataError, setDataError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setDataError(await response.text());
        }

        const result = await response.json();
        setCategories(result);
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteShop = async () => {
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/AreaManagers/deleteShop?loginId=${loginId}&tankNo=${category}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const error = await response.text();
        setError("Gözlənilməz xəta baş verdi");
        return;
      }

      setSuccess(true);
      navigate("/admin/areamanagers");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      {loading && <p className="p-3 text-warning">...Yüklənir</p>}
      {error && <p className="p-3 text-danger">Xəta baş verdi: {error}</p>}
      <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-2">
        <div className="bg-info text-white py-4 px-4 rounded position-relative w-40">
          <p
            className="mb-3"
            style={{ fontSize: "24px", fontWeight: "400", color: "white" }}
          >
            Kateqoriya Sil
          </p>
          <FormGroup>
            <Label htmlFor="loginId">loginId</Label>
            <Input
              className="text-dark customInp"
              id="loginId"
              name="loginId"
              placeholder="loginId Daxil edin"
              type="text"
              onChange={(e) => setLoginId(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="categoryId">Kateqoriyalar</Label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="form-control text-black customSLc"
              name="categoryId"
            >
              <option
                style={{
                  cursor: "pointer",
                  fontWeight: "600",
                  color: "red",
                }}
                value=""
                onClick={() => {
                  setCategory([]);
                }}
              >
                Sıfırla
              </option>
              {categories &&
                categories.map((cat, key) => {
                  return (
                    <option
                      style={{ color: "black" }}
                      key={key}
                      value={cat.categoryId}
                    >
                      {cat.categoryName}
                    </option>
                  );
                })}
            </select>
          </FormGroup>
          <div className="d-flex gap-2">
            <Button onClick={() => handleDeleteShop()}>Yadda Saxla</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaManagerDeleteCategory;
