import { apiKey } from "BaseApi/ApiKey";
import classNames from "classnames";
import Pagination from "react-bootstrap/Pagination";
import { CardTitle } from "reactstrap/es";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap/es";
import { Table } from "reactstrap";
import { jwtDecode } from "jwt-decode";

const CreateOrder = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("3");
  const [currentProduct, setCurrentProduct] = useState();
  const navigate = useNavigate();

  const [categoryNames, setCategoryNames] = useState();

  const [page, setPage] = useState({
    current: 1,
    last: null,
    firstPages: 4,
    lastPages: 4,
  });
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = Cookies.get("token");
        const encodedToken = jwtDecode(token);
        const shopId =
          encodedToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ];

        const response = await fetch(
          `${apiKey}/nam/Products/getAll?shopId=${shopId}&search=${search}&page=${page.current}&limit=10`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }
        const result = await response.json();

        setData(result.data);
        setPage((prevData) => ({
          ...prevData,
          last: result.pageCount,
        }));
        
      } catch (error) {
        console.log(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/categories/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        const allCategories = result
          .filter((obj) => obj.isActive != false)
          .map((obj) => obj.categoryName);
        setCategoryNames([...new Set(allCategories)]);
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const CreateOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const data = {
        orderItems: orders,
      };
      const response = await fetch(`${apiKey}/nam/Orders/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/orders/orderlist");
    } catch (error) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      {loading && <p className="p-3 text-warning">...Yüklənir</p>}
      {error && <p className="p-3 text-danger">{error}</p>}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Məhsul Əlavə Et
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Sifariş
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            Məhsul Cədvəli
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane className="py-3" tabId="1">
          {currentProduct ? (
            <AddProduct
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
              orders={orders}
              setOrders={setOrders}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              loading={loading}
              setLoading={setLoading}
              error={error}
              setError={setError}
            />
          ) : (
            <p>Halhazırda seçilmiş məhsul yoxdur</p>
          )}
        </TabPane>
        <TabPane className="py-3" tabId="2">
          {orders.length > 0 ? (
            <OrdersList
              orders={orders}
              setOrders={setOrders}
              sendOrder={CreateOrder}
              loading={loading}
            />
          ) : (
            <p>Halhazırda sifarişdə məhsul yoxdur</p>
          )}
        </TabPane>
        <TabPane tabId="3">
          {(
            <ProductsTable
              page={page}
              setPage={setPage}
              data={data}
              setData={setData}
              categoryNames={categoryNames}
              setCurrentProduct={setCurrentProduct}
              setActiveTab={setActiveTab}
              search={search}
              setSearch={setSearch}
            />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

const AddProduct = ({
  currentProduct,
  setCurrentProduct,
  orders,
  setOrders,
  activeTab,
  setActiveTab,
  setLoading,
  setError
}) => {
  const [previousPrice, setPreviousPrice] = useState(null);
  const [tankNo, setTankNo] = useState("");
  const [predict, setPredict] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const AddToOrder = () => {
    const index = orders.findIndex(
      (order) => order.productId === currentProduct.productId
    );
    if (index !== -1) {
      setOrders((prev) => {
        const updatedOrders = [...prev];
        const newValue =
          parseInt(updatedOrders[index].value) + parseInt(currentProduct.value);
        updatedOrders[index] = {
          ...updatedOrders[index],
          value: newValue,
        };
        return updatedOrders;
      });
    } else {
      setOrders((prev) => [...prev, currentProduct]);
    }
    setCurrentProduct(null);
    setActiveTab("3");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = Cookies.get("token");
        const encodedToken = jwtDecode(token);
        const shopId =
          encodedToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ];

        const response = await fetch(
          `${apiKey}/nam/Shops/getShop?id=${shopId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }
        const result = await response.json();
        setTankNo(result.tankNo);

      } catch (error) {
        console.log(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if(tankNo){
        try {
          setLoading(true);
          const token = Cookies.get("token");

          const response = await fetch(
            `${apiKey}/nam/orders/predict?tankNo=${tankNo}&stockCode=${currentProduct.stockCode}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!response.ok) {
            setError(await response.text());
            return;
          }
          const result = await response.json();
          setPredict(result);
        } catch (error) {
          console.log(error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
      
    };

    fetchData();
  }, [tankNo]);

  useEffect(() => {
    const index = orders.findIndex(
      (order) => order.productId === currentProduct.productId
    );
    setPreviousPrice(orders[index]?.value);
  }, [activeTab]);

  return (
    <div className=" p-2">
      <h3 className="mb-2">Məhsul: {currentProduct.stockName}</h3>
      {currentProduct.malQaligi && (
        <p className="mb-3 text-danger">
          Mehsul qaligi: {currentProduct.malQaligi}
        </p>
      )}
      {
        predict && (
          <p className="mb-3 mt-2 text-success">
            Təxmini satış : {predict.toFixed(2)}
          </p>
        )
      }
      {previousPrice && (
        <p className="text-warning">
          Əlavə etdiyiniz məhsuldan sifarişdə {previousPrice} (kq/ədəd)
          mövcuddur! Yazdığınız miqdar üzərinə əlavə olunacaq.
        </p>
      )}
      <Label htmlFor={`value`}>Məhsulun Miqdarı*</Label>
      <Input
        required
        value={currentProduct.value}
        onChange={handleInputChange}
        className="w-50 mb-3"
        inputMode="numeric"
        type="number"
        name="value"
        id={`value`}
        placeholder="Miqdarı daxil et"
      />
      <Label htmlFor={`note`}>Qeyd</Label>
      <textarea
        value={currentProduct.note}
        onChange={handleInputChange}
        className="w-50 form-control px-3"
        type="number"
        name="note"
        id={`note`}
        placeholder="Qeyd yaz"
      />
      <div className="d-flex gap-2 flex-wrap mt-2">
        <Button color="success" onClick={AddToOrder}>
          Əlavə et
        </Button>
        <Button color="danger" onClick={() => setCurrentProduct(null)}>
          Ləğv et
        </Button>
      </div>
    </div>
  );
};

const OrdersList = ({ orders, setOrders, sendOrder, loading }) => {
  const [totalSum, setTotalSum] = useState(0);
  const RemoveProduct = (key) => {
    const newArray = [...orders];
    newArray.splice(key, 1);
    setOrders(newArray);
  };
  useEffect(() => {
    let totalSums = 0;
    orders.forEach((item) => {
      const value = parseFloat(item.value) || 0;
      const price = parseFloat(item.price) || 0;
      if (!isNaN(price)) {
        totalSums += value * price;
      }
    });
    setTotalSum(totalSums);
  }, [orders]);
  return (
    <Fragment>
      <Button disabled={loading} onClick={sendOrder} color="info">
        Sifarişi Təsdiqlə (Ümumi Cəmi: {totalSum.toFixed(2)})
      </Button>
      <Table
        className="table-dark table-striped table-hover tablesorter text-center"
        responsive
      >
        <thead className="text-primary">
          <tr>
            <th>Məhsulun adı</th>
            <th>Qiyməti (kq,ədəd)</th>
            <th>Miqdarı</th>
            <th>Cəmi</th>
            <th>Qeyd</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.stockName}</td>
                  <td>{item.price ? item.price : "-"}</td>
                  <td>{item.value}</td>
                  <td>
                    {item.price ? (item.price * item.value).toFixed(2) : "-"}
                  </td>
                  <td>{item.note || item.note.length > 0 ? item.note : "-"}</td>
                  <td>
                    <Button onClick={() => RemoveProduct(key)} color="danger">
                      <i className="fa-solid fa-trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Fragment>
  );
};

const ProductsTable = ({
  categoryNames,
  page,
  setPage,
  setCurrentProduct,
  setActiveTab,
  search,
  setSearch
}) => {
  const [data, setData] = useState(null);
  const [value, setValue] = useState(null);
  const [cat, setCat] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=> {
    if(cat){
      const fetchData = async () => {
        try {
          setLoading(true);
          const token = Cookies.get("token");
          const encodedToken = jwtDecode(token);
          const shopId =
            encodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ];

          const response = await fetch(
            `${apiKey}/nam/Products/getAll?shopId=${shopId}&category=${cat}&search=${search}&page=${page.current}&limit=10`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!response.ok) {
            setError(await response.text());
            return;
          }
          const result = await response.json();
          console.log(result);

          setData(result.data);
          setPage((prevData) => ({
            ...prevData,
            last: result.pageCount,
          }));
        } catch (error) {
          console.log(error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [search,page.current, cat])

  const AddOrder = (id, name, price, malQaligi, stockCode) => {
    const newOrder = {
      productId: id,
      malQaligi: malQaligi,
      value: null,
      note: "",
      stockName: name,
      price: price,
      stockCode: stockCode
    };
    setCurrentProduct(newOrder);
    setActiveTab("1");
  };

  const pages = Array.from({ length: page.last }, (_, i) => i + 1);

  const displayPages = pages.filter((pagee, index) => {
    if (index < page.firstPages) {
      return true;
    }
    if (index >= page.last - page.lastPages) {
      return true;
    }
    if (pagee === page.current - 1 || pagee === page.current + 1) {
      return true;
    }
    if (pagee === page.current) {
      return true;
    }
    return false;
  });

  const hasEllipsis = (start, end) => {
    const range = Array.from({ length: end - start }, (_, i) => start + i);
    return range.some((page) => !displayPages.includes(page));
  };

  return (
    <div className="content border-top pt-4">
      <CardTitle className="d-flex justify-content-lg-between align-items-lg-center flex-column flex-lg-row">
        <select
          onChange={(e) => setCat(e.target.value)}
          className="form-control bg-dark text-white"
          style={{ width: "200px" }}
        >
          <option value="">Kateqoriya Seçin</option>
          {categoryNames &&
            categoryNames.map((name, key) => {
              return (
                <option key={key} value={name}>
                  {name}
                </option>
              );
            })}
        </select>
        <div className="d-flex gap-3 align-items-center w-100 w-lg-50 justify-content-lg-end mt-3 mt-sm-0">
          <Input
            className="w-50 "
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="text"
            id="search"
            name="search"
            placeholder="Axtarış dəyərini daxil edin!"
          ></Input>
          <Button onClick={() => setSearch(value)}>Axtar</Button>
        </div>
      </CardTitle>

      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <>
          {data ? (
            <>
              <Table striped bordered hover className="mt-4">
                <thead>
                  <tr>
                    <th className="bg-dark">Barkod</th>
                    <th className="bg-dark">Mehsulun adi</th>
                    <th className="bg-dark">Diger</th>
                    <th className="bg-dark">Kategoriya</th>
                    <th className="bg-dark">malQaligi</th>
                    <th className="bg-dark">Qiymeti</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((product) => (
                    <tr className="text-center" key={product.productId}>
                      <td className="bg-dark">{product.barCode}</td>
                      <td className="bg-dark">{product.stockName}</td>
                      <td className="bg-dark">
                        {
                          <Link
                            className="btn btn-warning"
                            onClick={() =>
                              AddOrder(
                                product.productId,
                                product.stockName,
                                product.price,
                                product.malQaliqi,
                                product.stockCode
                              )
                            }
                          >
                            +
                          </Link>
                        }
                      </td>
                      <td className="bg-dark">{product.categoryName}</td>
                      <td className="bg-dark">{product.malQaliqi ? product.malQaligi : "-"}</td>
                      <td className="bg-dark">{product.price ? product.price : "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>{" "}
              <Pagination>
                <Pagination.First
                  onClick={() =>
                    setPage((prevData) => ({
                      ...prevData,
                      current: 1,
                    }))
                  }
                />
                <Pagination.Prev
                  onClick={() =>
                    setPage((prevData) => ({
                      ...prevData,
                      current: page.current - 1,
                    }))
                  }
                />

                {displayPages.map((pagee, index) => (
                  <React.Fragment key={pagee}>
                    {index > 0 &&
                      hasEllipsis(displayPages[index - 1] + 1, pagee) && (
                        <Pagination.Ellipsis
                          disabled
                          key={`ellipsis-${index}`}
                        />
                      )}
                    <Pagination.Item
                      active={pagee === page.current}
                      onClick={() =>
                        setPage((prevData) => ({
                          ...prevData,
                          current: pagee,
                        }))
                      }
                    >
                      {pagee}
                    </Pagination.Item>
                  </React.Fragment>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setPage((prevData) => ({
                      ...prevData,
                      current: page.current + 1,
                    }))
                  }
                />
                <Pagination.Last
                  onClick={() =>
                    setPage((prevData) => ({
                      ...prevData,
                      current: page.last,
                    }))
                  }
                />
              </Pagination>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
export default CreateOrder;
