import React, { useEffect, useState } from 'react'
import { MDBDataTable } from "mdbreact";
import Cookies from "js-cookie";
import { apiKey } from "BaseApi/ApiKey";
import { Link } from "react-router-dom";
import {
  CardTitle,
} from "reactstrap/es";


const AreaManagerShops = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [data, setData] = useState({
      columns: [
        {
          label: "Mağazanın adı",
          field: "name",
          sort: "asc",
        },
        {
          label: "DepoNo",
          field: "depoNo",
          sort: "asc",
        },
        {
          label: "Satış qiymətləri",
          field: "displayPrice",
          sort: "asc",
        },
        {
          label: "Endirim Qiymətləri",
          field: "discountedPrice",
          sort: "asc",
        },
        {
          label: "Mal Qalığı",
          field: "malQaligi",
          sort: "asc",
        },
      ],
      rows: [],
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const jwtToken = Cookies.get("token");

          const response = await fetch(`${apiKey}/nam/areaManagers/getShops`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          });
          if (!response.ok) {
            setError(await response.text());
            return;
          }

          const result = await response.json();

          const rowsData = result.map((shop) => ({
            name: shop.name,
            depoNo: shop.depoNo,
            discountedPrice: (
              <Link
                className="btn btn-info"
                to={`/manager/areaShop/discountedPrices/${shop.depoNo}`}
              >
                <i className="fa-solid fa-circle-info" />
              </Link>
            ),
            displayPrice: (
              <Link
                className="btn btn-info"
                to={`/manager/areaShop/displayPrices/${shop.depoNo}`}
              >
                <i className="fa-solid fa-circle-info" />
              </Link>
            ),
            malQaligi: (
              <Link
                className="btn btn-info"
                to={`/manager/areaShop/restOfTheProduct/${shop.depoNo}`}
              >
                <i className="fa-solid fa-circle-info" />
              </Link>
            ),
          }));
          setData((prevData) => ({
            ...prevData,
            rows: rowsData,
          }));
        } catch (error) {
          setError("Gözlənilməz xəta baş verdi");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, []);
  return (
    <div className="content">
      <CardTitle tag="h4">
        <p>Magazalar</p>
      </CardTitle>
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      )}
    </div>
  );
}

export default AreaManagerShops
