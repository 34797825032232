import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap/es";

const CreateAreaManager = () => {
  const [error, setError] = useState(null);
  const [areaManagerData, setAreaManagerData] = useState({
    name: "",
    surName: "",
    email: "",
    loginId: "",
    password: "",
    phoneNuber: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(null);

  const CreateProduct = async (e) => {
    console.log(areaManagerData);
    e.preventDefault();
    setLoading(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(`${apiKey}/nam/areaManagers/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(areaManagerData),
      });

      if (!response.ok) {
        setError(await response.text());
        return;
      }
      navigate("/admin/areaManagers");
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const InputChange = (e) => {
    const { name, value } = e.target;

    setIsCheck(null);

    if (name == "loginId" && value.length > 0 && value.charAt(0) !== "$") {
      setIsCheck("LoginId '$' simvol ile başlamalidi!");
    }
    setAreaManagerData((prevProductData) => ({
      ...prevProductData,
      [name]: value,
    }));
  };

  return (
    <div className="content">
      <Form onSubmit={CreateProduct}>
        {error && <p className="text-danger">{error}</p>}
        <Label htmlFor="name">Adı*</Label>
        <Input
          required
          value={areaManagerData.name}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="name"
          id="name"
          placeholder="Adını daxil et"
        />

        <Label htmlFor="surName">Soyad*</Label>
        <Input
          required
          value={areaManagerData.surName}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="surName"
          id="surName"
          placeholder="Soyadini daxil et"
        />

        <Label htmlFor="email">Email</Label>
        <Input
          value={areaManagerData.email}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="email"
          id="email"
          placeholder="Email daxil et"
        />

        <Label htmlFor="loginId">Login Id*</Label>
        <Input
          required
          value={areaManagerData.loginId}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="loginId"
          id="loginId"
          placeholder="LoginId daxil et"
        />

        {isCheck ? <p className="m-0 pt-2 text-danger">{isCheck}</p> : null}

        <Label htmlFor="passWord">Hesab Şifrəsi*</Label>
        <Input
          required
          value={areaManagerData.password}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="password"
          id="password"
          placeholder="Şifrəni daxil et"
        />

        <Label htmlFor="phoneNuber">Elaqe Nomresi*</Label>
        <Input
          inputMode="tel"
          required
          value={areaManagerData.phoneNumber}
          onChange={InputChange}
          className="w-50"
          type="text"
          name="phoneNuber"
          id="phoneNuber"
          placeholder="Telefon nömrəsini daxil et"
        />

        <Button type="submit" disabled={loading}>
          Yarat
        </Button>
      </Form>
    </div>
  );
};

export default CreateAreaManager;
