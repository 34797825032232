import { Label } from "flowbite-react";
import React, { Fragment, useEffect, useState } from "react";
import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import "views/ProductChangeVisibility/index.scss";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

const ProductChangeVisibility = () => {
  const [shopTableModal, setShopTableModal] = useState(false);
  const [choosedShops, setChoosedShops] = useState([]);
  const [inputValue, setInputValue] = useState({
    writingValue: "",
    wantedValue: "",
  });

  const [isExist, setIsExist] = useState(false);
  const [depoNo, setDepoNo] = useState(null);
  const [shops, setShops] = useState(null);
  const [barCode, setBarCode] = useState(null);
  const [visibility, setVisibility] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    changeVisibility: null,
    showVisibilty: null,
  });
  const [success, setSuccess] = useState({
    changeVisibility: null,
    showVisibilty: null,
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(`${apiKey}/nam/shops/all`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          setError(await response.text());
        }

        const result = await response.json();
        setShops(result);
      } catch (error) {
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpperSearch = (value) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      writingValue: value,
    }));

    const shopName = shops
      .map((shop) => shop.name)
      .find((name) => name === value);

    const shopDepoNo = shops
      .map((shop) => shop.tankNo)
      .find((tankNo) => tankNo === value);

    setIsExist(false);

    if (
      shopName &&
      !choosedShops.find((choosed) => choosed.name === shopName)
    ) {
      setIsExist(true);

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        wantedValue: shops.find(
          (shop) => shop.tankNo === value || shop.name === value
        ).name,
      }));
    }

    if (
      shopDepoNo &&
      !choosedShops.find((choosed) => choosed.tankNo === shopDepoNo)
    ) {
      setIsExist(true);

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        wantedValue: shops.find(
          (shop) => shop.tankNo === value || shop.name === value
        ).name,
      }));
    }
  };

  const handleAddChangeShops = () => {
    const result = shops.find(
      (shop) => shop.tankNo === inputValue.writingValue || shop.name === inputValue.writingValue
    );
    setChoosedShops((prevShops) => [...prevShops, result]);
    setInputValue("");
    setIsExist(false);
  };

  const handleChangeVisibilty = async (type) => {
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");

      const response = await fetch(
        `${apiKey}/nam/shops/updateProductFS?productBarcode=${barCode}&active=${visibility}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify(choosedShops),
        }
      );
      if (!response.ok) {
        setError(await response.text());
        return;
      }

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  const handleVisibiltyShow = async (type) => {
    try {
      setLoading(true);
      const jwtToken = Cookies.get("token");

      const response = await fetch(
        `${apiKey}/nam/shops/searchProductFS?depoNo=${depoNo}&productBarcode=${barCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (!response.ok) {
        setError(await response.text());
        return;
      }

      const result = await response.json();
      setData(result);
      console.log(data);

      setError((prev) => ({
        ...prev,
        [type]: null,
      }));
      setSuccess((prev) => ({
        ...prev,
        [type]: true,
      }));
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <div className="d-flex gap-2 flex-wrap text-center mb-4 mx-2">
        <div className="bg-info text-white py-4 px-4 rounded position-relative">
          <div className="mb-2 block">
            <Label
              htmlFor="ihes"
              style={{ fontSize: "1.8em" }}
              value="Mağaza Mallarinin A/P Edilmesi"
              className="text-lg"
            />
            <FormGroup className="position-relative">
              <Label htmlFor="shop">Kateqoriyalar</Label>
              <Input
                className={`customInp`}
                id="shop"
                name="shop"
                placeholder="Magaza daxil edin"
                type="text"
                value={inputValue.writingValue}
                onChange={(e) => handleUpperSearch(e.target.value)}
              />
              <button
                className="position-absolute infoBtn"
                onClick={() => setShopTableModal(true)}
              >
                <i className="fa-solid fa-info"></i>
              </button>
              <ShopTableModal
                modal={shopTableModal}
                setModal={setShopTableModal}
                choosedShops={choosedShops}
                setChoosedShops={setChoosedShops}
              />
            </FormGroup>
            {isExist && (
              <>
                <div className="d-flex align-items-center gap-2">
                  <p className="text-success m-0">{inputValue.wantedValue}</p>
                  <button onClick={handleAddChangeShops} className="checkBtn">
                    <i className="fa-solid fa-check"></i>
                  </button>
                </div>
              </>
            )}
            <FormGroup>
              <Label htmlFor="barCode">BarCode</Label>
              <Input
                className="text-black customInp"
                id="barCode"
                name="barCode"
                placeholder="BarCode Daxil edin"
                type="number"
                onChange={(e) => setBarCode(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="apVisibility">A/P Seçin</Label>
              <select
                onChange={(e) => setVisibility(e.target.value)}
                className="form-control text-black customSlc"
                name="apVisibility"
              >
                <option disabled selected value="">
                  Choose
                </option>
                <option value={true}>Active</option>
                <option value={false}>Passive</option>
              </select>
            </FormGroup>
          </div>
          <div className="d-flex flex-column align-items-center gap-2 row">
            <Button
              disabled={loading}
              className="w-30 col-6"
              color="default"
              onClick={() => handleChangeVisibilty("changeVisibility")}
            >
              Saxla
            </Button>
            {error.changeVisibility && (
              <p className="bg-danger rounded p-1">
                Xəta: {error.changeVisibility}
              </p>
            )}
            {success.changeVisibility && (
              <p className="bg-success rounded text-dark p-1">
                Uğurla Deyişdirildi
              </p>
            )}
          </div>
        </div>
        <div className="bg-info text-white py-4 px-4 rounded position-relative">
          <div className="mb-2 block">
            <Label
              htmlFor="ihes"
              style={{ fontSize: "1.8em" }}
              value="Mağaza Mallarinin A/P Edilmesi"
              className="text-lg"
            />
            <FormGroup>
              <Label htmlFor="depoNoShow">DepoNo</Label>
              <Input
                className="text-dark customInp"
                id="depoNoShow"
                name="depoNoShow"
                placeholder="DepoNo Daxil edin"
                type="number"
                onChange={(e) => setDepoNo(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="barCodeShow">BarCode</Label>
              <Input
                className="text-dark customInp"
                id="barCodeShow"
                name="barCodeShow"
                placeholder="BarCode Daxil edin"
                type="number"
                onChange={(e) => setBarCode(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="d-flex flex-column align-items-center gap-2 row">
            <Button
              disabled={loading}
              className="w-30 col-6"
              color="warning"
              onClick={() => handleVisibiltyShow("showVisibilty")}
            >
              Goster
            </Button>
            {error.showVisibilty && (
              <p className="bg-danger rounded p-1">
                Xəta: {error.showVisibilty}
              </p>
            )}
            {success.showVisibilty &&
              (data.isActive ? (
                <p className="bg-success rounded text-dark p-1">
                  {`Mehsulun Veziyyeti: ${data.isActive && "Active"}`}
                </p>
              ) : (
                <p className="bg-danger rounded text-white p-1">
                  {`Mehsulun Veziyyeti: ${!data.isActive && "Passive"}`}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ShopTableModal = ({ modal, setModal, choosedShops, setChoosedShops }) => {

  const handleDeleteChoosenShop = (depoNo) => {
    const newChoosenShops = choosedShops.filter(
      (shop) => shop.tankNo !== depoNo
    );

    console.log(newChoosenShops);
    

    setChoosedShops(newChoosenShops);
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(!modal)}>
        <p className="h4">Seçilmiş Mağazalar</p>
      </ModalHeader>
      <ModalBody>
        {choosedShops.length > 0 ? (
          <Fragment>
            <Table dark responsive>
              <thead className="text-primary">
                <tr>
                  <th scope="col">№</th>
                  <th scope="col">Mağaza Adı</th>
                  <th scope="col">DepoNo</th>
                  <th scope="col">Sil</th>
                </tr>
              </thead>
              <tbody>
                {choosedShops.map((shop, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{shop.name}</td>
                    <td>{shop.tankNo}</td>
                    <td>
                      <Button
                        className="btn-danger"
                        onClick={()=> handleDeleteChoosenShop(shop.tankNo)}
                      >
                        Sil
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Fragment>
        ) : (
          <p className="text-center text-danger h3 mt-3">
            Seçilmiş mağaza yoxdur!
          </p>
        )}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ProductChangeVisibility;
