import { apiKey } from 'BaseApi/ApiKey';
import Cookies from 'js-cookie';
import React, { Fragment, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const Delete = ({ type, message, id, setRefresh }) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false);
  const DeleteCategory = async (id) => {
    try {
      setLoading(true);
      try {
        const jwtToken = Cookies.get('token');

        const response = await fetch(`${apiKey}/nam/${type}/delete?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          setError(response.statusText);
          return;
        }
        setModal(!modal);
        setRefresh(prev=>!prev)
      } catch (error) {
        setError(error?.message);
      }
      finally {
        setLoading(false);
      }
    } catch (error) {

    }
  }
  return (
    <Fragment>
      <Button className='btn btn-danger' onClick={() => setModal(!modal)}>
        <i className="fa-solid fa-trash" />
      </Button>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          {error && <p className='text-danger'>{error}</p>}
          <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button color='info' disabled={loading} onClick={()=>DeleteCategory(id)}>
            Sil
          </Button>{' '}
          <Button color="secondary" onClick={() => setModal(false)}>
            İmtina et
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default Delete