import { apiKey } from "BaseApi/ApiKey";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

const AreaManagerOrders = () => {
  const [data, setData] = useState({
    columns: [
      {
        label: "Magaza Adi",
        field: "shopName",
        sort: "asc",
      },
      {
        label: "DepoNo",
        field: "tankNo",
        sort: "asc",
      },
      {
        label: "Umumi Sifaris Qiymeti",
        field: "total",
        sort: "asc",
      },
      {
        label: "Tarix",
        field: "date",
        sort: "asc",
      },
      {
        label: "Tesdiqle",
        field: "verify",
        sort: "asc",
      },
      {
        label: "Sifarişi Deyiş",
        field: "other",
        sort: "asc",
      },
    ],
    rows: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleConfirmOrder = async (id) => {
    setLoading(true);
    setRefresh(true);
    try {
      const jwtToken = Cookies.get("token");
      const response = await fetch(
        `${apiKey}/nam/AreaManagers/verify?id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(response);
      if (!response.ok) {
        const error = await response.text();
        setError(error);
        return;
      }
    } catch (error) {
      console.log(error);
      setError("Gözlənilməz xəta baş verdi");
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = Cookies.get("token");

        const response = await fetch(
          `${apiKey}/nam/AreaManagers/getAllOrders`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (!response.ok) {
          setError(await response.text());
          return;
        }

        const result = await response.json();
        console.log(result);
        const rowsData = result.map((order) => ({
          shopName: order.shopName,
          tankNo: order.tankNo,
          total: order.total.toFixed(2),
          date: order.date.split("T")[0],
          verify: (
            <Button
              disabled={loading}
              className="btn btn-success btn-md"
              onClick={() => handleConfirmOrder(order.id)}
            >
              <i className="fa-solid fa-check"></i>
            </Button>
          ),
          other: (
            <div className="d-flex gap-2 flex-wrap justify-content-center">
              <div className="d-flex gap-2 flex-wrap justify-content-center">
                <Link
                  className="btn btn-warning"
                  to={`/manager/updateOrder/${order.id}`}
                >
                  <i className="fa-solid fa-pen-to-square" />
                </Link>
              </div>
            </div>
          ),
        }));
        setData((prevData) => ({
          ...prevData,
          rows: rowsData,
        }));
      } catch (error) {
        console.log(error);
        setError("Gözlənilməz xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);
  return (
    <div className="content">
      {loading ? (
        <p className="p-3 text-warning">...Yüklənir</p>
      ) : error ? (
        <p className="p-3 text-danger">Xəta baş verdi: {error}</p>
      ) : (
        <MDBDataTable
          data-bs-theme="dark"
          responsiveMd
          striped
          bordered
          hover
          searchLabel="Axtarış"
          paginationLabel={["Əvvəl", "Növbəti"]}
          data={data}
        />
      )}
    </div>
  );
};

export default AreaManagerOrders;
